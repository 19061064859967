import React, { createContext } from 'react'
import { useMemo } from 'react'
import { useContext } from 'react'
import { RUTAS } from '../constants/routes.constants'

const I18N_CONFIG_KEY = 'i18nConfig'
const initialState = {
	selectedLang: 'es'
}

export function getConfig() {
	const ls = localStorage.getItem(I18N_CONFIG_KEY)
	if (ls)
		try {
			return JSON.parse(ls)
		} catch (er) {
			console.error(er)
		}

	return initialState
}

const findLangRoute = pathname => {
	const _PATHNAME = pathname.split('/')
  let _RUTA = 'BASE'

  Object.keys(RUTAS).forEach(route => {
    Object.keys(RUTAS[route]).forEach(lang => {
			const _PATH = RUTAS[route][lang].split('/')
			if (_PATH.length !== _PATHNAME.length) return

			let valid = true
			_PATH.forEach((elem, index) => {
				if (elem !== _PATHNAME[index] && elem[0] !== ':')
					valid = false
			})

      if (valid)
       _RUTA = route
    })
  })
  return _RUTA
}

// Side effect
export function setLanguage(lang, pathname) {
	localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }))

	const RUTA = findLangRoute(pathname)

	const baseLocation = window.location.protocol + '//' + window.location.host
	//const baseLocation = window.location.protocol + '//' + window.location.host + (window.location.host === 'http://92.222.217.72/') ? '/hubdigital' : ''
	//para que funciona en ip provisional, descomentar

	/*const baseLocation = window.location.host === 'http://92...'
	? window.location.protocol + '//' + window.location.host + '/hubdigital'
	: window.location.protocol + '//' + window.location.host*/

	const newLocation =
	(window.location.href.includes('/video/') || window.location.href.includes('/podcast/'))
		?	`${baseLocation}${RUTAS[RUTA][lang].replace(':name', JSON.parse(localStorage.getItem('seoURL') || {})[lang])}`
		: `${baseLocation}${RUTAS[RUTA][lang]}`

	window.location.href = newLocation
}

export function setLanguageHome(lang) {
	localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }))
}

const I18nContext = createContext()

export function useLang() {
	const selLang = useContext(I18nContext)?.selectedLang || 'es'

	return selLang
}

export function withI18n(Component) {
	class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
    	return <Component {...this.props} menu={this.context} />
    }
	}

	return WithI18n
}

export const I18nConsumer = I18nContext.Consumer

export function CustomI18nProvider({ children }) {
	const lang = useMemo(getConfig, [])

	return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}
