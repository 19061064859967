import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()

export const getVideos = async (tag, featured) => {
	let _endpoint = 'video?'
	if (tag)
		_endpoint = `${_endpoint}tag=${tag}&`
	if (featured)
		_endpoint = `${_endpoint}featured=${featured}&`

	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}

export const getVideosByTag = async (tags) => {
	const _endpoint = 'video/tag'
	const URL = `${API}/${_endpoint}?tags=${tags}`

	return instance.get(URL)
}

export const getVideo = async (id) => {
	const _endpoint = `video/${id}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}

export const getVideoByURL = async (url) => {
	const _endpoint = `video/url/${url}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}
