import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Blockleft from '../components/blockleft'
import Blockright from '../components/blockright'
import { useVideos } from '../hooks/useVideos'
import { usePodcasts } from '../hooks/usePodcasts'
import { useTags } from '../hooks/useTags'
import { Link } from 'react-router-dom'
import { SERVER_URL } from '../utils'
import Carousel from 'react-bootstrap/Carousel'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'
import { RUTAS } from '../constants/routes.constants'
import TranslatedLink from '../components/navigation/TranslatedLink'
import HrButton from '../components/layout/HrButton'
import Typewriter from 'typewriter-effect'
import clsx from 'clsx'

function getEveryNth(arr, nth) {
	const result = []

	for (let i = 0; i < arr.length; i += nth)
		result.push(arr.slice(i, i+nth))


	return result
}

function Home({ intl }) {
	const [data, setData] = useState([])
	const [dataPodcasts, setDataPodcasts] = useState([])
	const [dataTags, setDataTags] = useState([])
	const { handleFetchVideos } = useVideos()
	const { handleFetchPodcasts } = usePodcasts()
	const { handleFetchTags } = useTags()

	const lang = useLang()


	useEffect(() => {
		window.scrollTo(0, 0)

		const asyncFetch = async () => {
			const data = await handleFetchVideos({ featured: true })
			setData(getEveryNth(data, 3))
		}
		const asyncFetchPodcasts = async () => {
			const dataPodcasts = await handleFetchPodcasts({ featured: true })
			if (!dataPodcasts?.length)
				return
			setDataPodcasts(dataPodcasts)
		}
		const asyncFetchTags = async () => {
			const dataTags = await handleFetchTags({ })
			setDataTags(dataTags)
		}
		asyncFetch()
		asyncFetchPodcasts()
		asyncFetchTags()
		// eslint-disable-next-line
	}, [])

	let count = 0

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'HOME.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'HOME.DESCRIPTIONSEO' }) || ''}
		/>
		<div className="container-fluid px-3 px-lg-5">
			<div className="row my-5">
				<div className="col-12 text-center py-5">
					<h1 className="h1">
						<div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'HOME.FABRICAMOS' }) } } />
						<Typewriter
							options={{loop: true}}
							onInit={(typewriter) => {
								typewriter
								.pauseFor(1000).typeString(intl.formatMessage({ id: 'HOME.FABR_1' })).pauseFor(1000).deleteAll()
								.typeString(intl.formatMessage({ id: 'HOME.FABR_2' })).pauseFor(1000).deleteAll()
								.typeString(intl.formatMessage({ id: 'HOME.FABR_3' })).pauseFor(1000).deleteAll()
								.start()
							}}
						/>
					</h1>
				</div>
			</div>
			{data?.map((elem, index) => {
				return (
					<>
						{index % 2 === 0 && <Blockleft
							home="1"
							typecontent="VIDEO"
							elem1={elem[0]}
							elem2={elem[1]}
							elem3={elem[2]}
						/>}
						{index % 2 === 1 && <Blockright
							home="1"
							typecontent="VIDEO"
							elem1={elem[0]}
							elem2={elem[1]}
							elem3={elem[2]}
						/>}
					</>)}
			)}
			<div className="row">
				<div className="col-12 text-center divmasinsp">
					<TranslatedLink to={RUTAS.VIDEOS}><button className={clsx('masinspiracionbutton', (navigator.userAgent.indexOf('Mac') === -1) ? '' : 'mac-os')}><FormattedMessage id='HOME.MASINSPIRACION' /></button><HrButton/></TranslatedLink>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-12 mt-5">
					<h2 className="mb-5"><FormattedMessage id='HOME.PODCASTSDESTACADOS' /></h2>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Carousel indicators='false'>
						{
							dataPodcasts?.map((elem) => {
								count++
								return (
									<Carousel.Item>
										<Link
											to={RUTAS.PODCAST[lang].replace(':name', elem.seoURL[lang])}
											state={elem._id}>
											<div className="row">
												<div className="col-12 col-md-7 position-relative">
													<img src={`${SERVER_URL}/${elem.image}`} alt={elem.summary && elem.summary[lang]} className="img-fluid imgcarousel dark"/>
													<div className="playover" style={{'top':'50%'}}>(PLAY)</div>
												</div>
												<div className="col-12 col-md-5 pb-5 pb-md-0 mb-4 mb-lg-0">
													<div className="carousel-nums d-none d-md-block fontbold position-absolute">{count <= 10 ? '0'+count : count} / {dataPodcasts.length <= 10 ? '0'+dataPodcasts.length : dataPodcasts.length}</div>
													<div className="d-flex align-items-end h-100">
														<div>
															<div className="fontmedium my-2">{elem.tags.map((elem) => {
	            										return (
	            											dataTags?.find(cat => cat._id === elem)?.fullName[lang] || '---'
	            										)
	            									}).join(' | ')}</div>
															<div className="fontlight fontsummary my-2">{elem.summary[lang]}</div>
															<div className="fontbook mt-4">{elem.duration}</div>
														</div>
													</div>
												</div>
											</div>
										</Link>
									</Carousel.Item>)}
							)}
					</Carousel>
				</div>
			</div>
			<div className="row my-5">
				<div className="col-12 text-center mb-5 divmasinsp">
					<TranslatedLink to={RUTAS.PODCASTS}><button className={clsx('masinspiracionbutton', (navigator.userAgent.indexOf('Mac') === -1) ? '' : 'mac-os')}><FormattedMessage id='HOME.MASINSPIRACION' /></button><HrButton/></TranslatedLink>
				</div>
			</div>
		</div>
	</>
    	)
}

export default injectIntl(Home)
