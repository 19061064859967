import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'

function PoliticaPrivacidad({ intl }) {

	const lang = useLang()


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	let txt = <>
							<h1>Política de privacidad</h1>
							<p className="p1">De conformidad con la Ley 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, le informamos que el Responsable del tratamiento de datos de carácter personal de este Site es:</p>
							<p className="p1">Consorci Zona Franca de Barcelona (CZFB)</p>
							<p className="p1">Av. Parc Logístic, 2-10, 08040 BARCELONA</p>
							<p className="p1">NIF:Q0876006H</p>
							<p className="p1">Tel: + 3493263811 E-mail: <a href="mailto:czfb@zfbarcelona.es">czfb@zfbarcelona.es</a></p>
							<p className="p1">Identificación del DPD: mail: <a href="mailto:DPD@zfbarcelona.es">DPD@zfbarcelona.es</a></p>
							<p className="p1">(CZFB), está especialmente sensibilizada en la protección de datos de carácter personal de los Usuarios de los servicios del sitio Web. Mediante la presente Política de Privacidad (o Política de Protección de Datos) (CZFB) informa a los USUARIOS de los sitios web: <a href="https://zfbarcelona.es" target="_blank" rel="noreferrer">https://zfbarcelona.es</a>, de los usos a los que se someten los datos de carácter personal que se recaban en sitio Web, con el fin de que decidan, libre y voluntariamente, si desean facilitar la información solicitada.</p>
							<p className="p1">(CZFB), se reserva la facultad de modificar esta Política con el objeto de adaptarla a novedades legislativas, criterios jurisprudenciales, prácticas del sector, o intereses de la entidad. Cualquier modificación en la misma será anunciada con la debida antelación, a fin de que tengas perfecto conocimiento de su contenido.</p>
							<p className="p3"><b>1 FINALIDAD DEL TRATAMIENTO</b></p>
							<p className="p1">La finalidad de la recogida y tratamiento de los datos personales, a través de los distintos formularios de (CZFB) puestos a disposición de los Usuarios,</p>
							<p className="p1">responden, según el caso concreto, para gestionar y atender a solicitudes de información, dudas, quejas o sugerencias a las publicaciones o a cualesquiera servicios o actividades, actos o eventos prestados, ofertados, patrocinados y/o sponsorizados por (CZFB) , así como conocer la opinión de los usuarios, y además, gestionar las solicitudes de empleo, mediante la recogida de currículums, con el fin de poder contactar con el interesado y llevar a cabo un proceso de selección.</p>
							<p className="p3"><b>2 PLAZO DE CONSERVACIÓN</b></p>
							<p className="p1">Los datos personales proporcionados se conservarán durante el plazo correspondiente para cumplir con las obligaciones legales, o se solicite su supresión por el interesado y este esté legitimado para ello.</p>
							<p className="p3"><b>3 LEGITIMACIÓN</b></p>
							<p className="p1">(CZFB) está legitimado al tratamiento de datos personales, en base al consentimiento otorgado por el interesado para uno o varios fines específicos, tal y como recoge el artículo 6.1. a) del Reglamento 2016/679 del Parlamento Europeo y del consejo de 27 de abril de 2016 relativo a la protección de las personas físicas.</p>
							<p className="p3"><b>4 EXACTITUD DE LOS DATOS</b></p>
							<p className="p1">Por otro lado, con el fin de que los datos obrantes en nuestros ficheros, informáticos y/o en papel, siempre correspondan a la realidad, se tratará de mantener actualizados. De manera que, a estos efectos, el Usuario deberá realizar los cambios, directamente, cuando así esté habilitado o comunicándose, por medio fehaciente, al área o departamento correspondiente de (CZFB).</p>
							<p className="p3"><b>5 DESTINATARIOS</b></p>
							<p className="p1">Los datos personales no serán cedidos o comunicados a terceros, salvo en los supuestos necesarios para el desarrollo, control y cumplimiento de la/s finalidad/es expresada/s, en los supuestos previstos según Ley.</p>
							<p className="p3"><b>6 DERECHOS DE LOS USUARIOS</b></p>
							<p className="p1">No obstante, el interesado de los datos personales en todo caso podrá ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protección de Datos, y que son:</p>
							<p className="p1">• Derecho a solicitar el acceso a los datos personales relativos al interesado,</p>
							<p className="p1">• Derecho a solicitar su rectificación o supresión,</p>
							<p className="p1">• Derecho a solicitar la limitación de su tratamiento,</p>
							<p className="p1">• Derecho a oponerse al tratamiento,</p>
							<p className="p1">• Derecho a la portabilidad de los datos</p>
							<p className="p1">El interesado podrá ejercitar tales derechos mediante solicitud acompañada de una fotocopia de su D.N.I, y en la que especificará cuál de éstos solicita sea satisfecho, remitida a la dirección: (CZFB) , domicilio social a estos efectos en Av. Parc Logístic, 2-10, 08040 Barcelona, España.</p>
							<p className="p1">En caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la Agencia Española de Protección de Datos (<span className="s1"><a href="https://www.agpd.es" target="_blank" rel="noreferrer">www.agpd.es</a></span>).</p>
							<p className="p3"><b>7 MEDIDAS DE SEGURIDAD</b></p>
							<p className="p1">Finalmente se informa que (CZFB) adoptará en su sistema de información las medidas técnicas y organizativas legalmente requeridas, a fin de garantizar la seguridad y confidencialidad de los datos almacenados, evitando así, su alteración, pérdida, tratamiento o acceso no autorizado; teniendo en cuenta el estado de la técnica, los costes de aplicación, y la naturaleza, el alcance, el contexto y los fines del tratamiento, así como riesgos de probabilidad y gravedad variables asociadas a cada uno de los tratamientos.</p>
							<p className="p3"><b>8 OBLIGATORIEDAD DE LA INTRODUCCION DE DATOS DE CARÁCTER PERSONAL</b>&nbsp;Para acceder al sitio web de (CZFB) <a href="https://elconsorci.es" target="_blank" rel="noreferrer">https://elconsorci.es</a> no es necesario aportar ningún dato de carácter personal. Sin embargo, para realizar solicitudes de información o realizar la solicitud de productos o servicios concretos sí puede ser necesario que el usuario, interesado o cliente aporte datos en los formularios existentes en el sitio web para las finalidades anteriormente descritas. Los campos en los que figure un asterisco (*) son aquellos que requieren una respuesta obligatoria. De no ser informados la consecuencia es la imposibilidad de enviar la solicitud de información.</p>
							<p className="p3"><b>9 EN RELACIÓN CON LOS DATOS FACILITADOS POR EL USUARIO O CLIENTE NEWSLETTER</b></p>
							<p className="p1">En relación con los datos de carácter personal facilitados por el usuario, interesado o cliente en el formulario de registro mail, Newsletter, ¿Quieres que te llamemos? y contactos, estos cumplen estrictamente la normativa vigente establecida en la Ley 15/1999 de Protección de Datos de Carácter Personal y su RD 1720/2007 que la desarrolla. CZFB informa al usuario, interesado o cliente que los referidos datos serán incluidos dentro de un fichero para su tratamiento automatizado y para poder atender la solicitud de información o contacto, prestando el usuario, interesado o cliente consentimiento mediante la aceptación de estas condiciones generales a dicho tratamiento.</p>
							<p className="p3"><b>10 EN RELACIÓN CON LOS DATOS FACILITADOS POR EL USUARIO O INTERESADO EN EL APARTADO TRABAJA CON NOSOTROS</b></p>
							<p className="p1">Asimismo, CZFB recoge determinados datos en el apartado de la web “Trabaja con Nosotros” relacionados con el área de recursos humanos con la finalidad de proceder a la selección de futuros trabajadores y distribuidores de la empresa. En todo caso, los datos recogidos y tratados por CZFB son únicamente de carácter básico y son los justos y necesarios para la finalidad señalada anteriormente.</p>
							<p className="p1">Si tiene alguna pregunta sobre esta Política de Privacidad, rogamos que se ponga en contacto con nosotros enviando un email a <span className="s1"><a href="mailto:czfb@zfbarcelona.es" target="_blank" rel="noreferrer">czfb@zfbarcelona.es</a></span> .</p>
						</>

	if (lang === 'ca')
			txt = <>
							<h1>Política de privacitat</h1>
							<p className="p1">De conformitat amb la Llei 2016/679 del Parlament Europeu i del Consell de 27 d’abril de 2016 relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d’aquestes dades, l’informem que el Responsable del tractament de dades de caràcter personal d’aquest Site és:</p>
							<p className="p1">Consorci Zona Franca de Barcelona (CZFB)</p>
							<p className="p1">Av. Parc Logístic, 2-10, 08040 BARCELONA</p>
							<p className="p1">NIF:Q0876006H</p>
							<p className="p1">Tel: + 3493263811 E-mail: <a href="mailto:czfb@zfbarcelona.es">czfb@zfbarcelona.es</a></p>
							<p className="p1">Identificació del DPD: mail: <a href="mailto:DPD@zfbarcelona.es">DPD@zfbarcelona.es</a></p>
							<p className="p1">(CZFB), ), està especialment sensibilitzat en la protecció de dades de caràcter personal dels Usuaris dels serveis del lloc web. Mitjançant la present Política de Privacitat (o Política de Protecció de Dades) (CZFB) informa els USUARIS dels llocs web: <a href="https://zfbarcelona.es" target="_blank" rel="noreferrer">https://zfbarcelona.es</a>, dels usos als quals se sotmeten les dades de caràcter personal que es recapten en lloc web, amb la finalitat que decideixin, lliure i voluntàriament, si desitgen facilitar la informació sol·licitada.</p>
							<p className="p1">(CZFB), es reserva la facultat de modificar aquesta Política a fi d’adaptar-la a novetats legislatives, criteris jurisprudencials, pràctiques del sector, o interessos de l’entitat. Qualsevol modificació en la mateixa serà anunciada amb la deguda antelació, a fi que tinguis perfecte coneixement del seu contingut.</p>
							<p className="p3"><b>1 FINALITAT DEL TRACTAMENTO</b></p>
							<p className="p1">La finalitat de la recollida i tractament de les dades personals, a través dels diferents formularis de (CZFB) posats a la disposició dels Usuaris, responen, segons el cas concret, per a gestionar i atendre sol·licituds d’informació, dubtes, queixes o suggeriments a les publicacions o a qualssevol serveis o activitats, actes o esdeveniments prestats, oferts, patrocinats i/o sponsoritzats per (CZFB), així com conèixer l’opinió dels usuaris i, a més, gestionar les sol·licituds d’ocupació, mitjançant la recollida de currículums, amb la finalitat de poder contactar amb l’interessat i dur a terme un procés de selecció.</p>
							<p className="p3"><b>2 TERMINI DE CONSERVACIÓ</b></p>
							<p className="p1">Les dades personals proporcionades es conservaran durant el termini corresponent per a complir amb les obligacions legals, o fins que se sol·liciti la seva supressió per part de l’interessat i aquest estigui legitimat per a fer-ho.</p>
							<p className="p3"><b>3 LEGITIMACIÓ</b></p>
							<p className="p1">(CZFB) està legitimat al tractament de dades personals, en base al consentiment atorgat per l’interessat per a un o diversos fins específics, tal com recull l’article 6.1. a) del Reglament 2016/679 del Parlament Europeu i del consell de 27 d’abril de 2016 relatiu a la protecció de les persones físiques.</p>
							<p className="p3"><b>4 EXACTITUT DE LES DADES</b></p>
							<p className="p1">D’altra banda, amb l’objectiu que els dades que consten en els nostres fitxers, informàtics i/o en paper, sempre corresponguin a la realitat, es procurarà mantenir-los actualitzats. De manera que, a aquest efecte, l’Usuari haurà de fer els canvis, directament, quan així estigui habilitat o comunicant-se, per mitjà fefaent, a l’àrea o departament corresponent de (CZFB).</p>
							<p className="p3"><b>5 DESTINATARIS</b></p>
							<p className="p1">Les dades personals no seran cedides o comunicades a tercers, excepte en els supòsits necessaris per al desenvolupament, control i compliment de la/les finalitat/s expressada/es, en els supòsits previstos segons la Llei.</p>
							<p className="p3"><b>6 DRETS DELS USUARIS</b></p>
							<p className="p1">No obstant això, l’interessat de les dades personals en tot cas podrà exercitar els drets que l’assisteixen, d’acord amb el Reglament General de Protecció de Dades, i que són:</p>
							<p className="p1">•	Dret a sol·licitar l’accés a les dades personals relatives a l’interessat,</p>
							<p className="p1">•	Dret a sol·licitar la seva rectificació o supressió,</p>
							<p className="p1">•	Dret a sol·licitar la limitació del seu tractament,</p>
							<p className="p1">•	Dret a oposar-se al tractament,</p>
							<p className="p1">•	Dret a la portabilitat de les dades</p>
							<p className="p1">L’interessat podrà exercitar aquests drets mitjançant sol·licitud acompanyada d’una fotocòpia del seu D.N.I, i en la qual s’especificarà quin d’aquests sol·licita sigui satisfet, remesa a l’adreça: (CZFB) , domicili social a aquests efectes a Av. Parc Logístic, 2-10, 08040 Barcelona, Espanya.</p>
							<p className="p1">En cas de considerar vulnerat el seu dret a la protecció de dades personals, podrà interposar una reclamació davant l’Agència Espanyola de Protecció de Dades (<span className="s1"><a href="https://www.agpd.es" target="_blank" rel="noreferrer">www.agpd.es</a></span>).</p>
							<p className="p3"><b>7 MESURES DE SEGURETAT</b></p>
							<p className="p1">Finalment s’informa que (CZFB) adoptarà al seu sistema d’informació les mesures tècniques i organitzatives legalment requerides, a fi de garantir la seguretat i confidencialitat de les dades emmagatzemades, evitant així, la seva alteració, pèrdua, tractament o accés no autoritzat; tenint en compte l’estat de la tècnica, els costos d’aplicació, i la natura, l’abast, el context i els objectius del tractament, així com riscos de probabilitat i gravetat variables associades a cadascun dels tractaments.</p>
							<p className="p3"><b>8 OBLIGATORIETAT DE LA INTRODUCCIÓ DE DADES DE CARÀCTER PERSONAL</b></p>
							<p>Per accedir al lloc web de (CZFB) <a href="https://elconsorci.es" target="_blank" rel="noreferrer">https://elconsorci.es</a> no és necessari aportar cap dada de caràcter personal. Tot i això, per realitzar sol·licituds d’informació o realitzar la sol·licitud de productes o serveis concrets sí que pot ser necessari que l’usuari, interessat o client aporti dades als formularis existents al lloc web per les finalitats anteriorment descrites. Els camps en què figuri un asterisc (*) són aquells que requereixen una resposta obligatòria. De no ser informats la conseqüència és la impossibilitat d’enviar la sol·licitud d’informació.</p>
							<p className="p3"><b>9 EN RELACIÓ AMB LES DADES FACILITADES PER L’USUARI O CLIENT NEWSLETTER</b></p>
							<p className="p1">En relació amb les dades de caràcter personal facilitades per l’usuari, interessat o client al formulari de registre mail, Newsletter, ¿Vols que et truquem? i contactes, aquests compleixen estrictament la normativa vigent establerta a la Llei 15/1999 de Protecció de Dades de Caràcter Personal i el seu RD 1720/2007 que la desenvolupa. CZFB informa l’usuari, interessat o client que les dades referides seran incloses dins d’un fitxer pel seu tractament automatitzat i per poder atendre la sol·licitud d’informació o contacte, prestant l’usuari, interessat o client consentiment mitjançant l’acceptació d’aquestes condicions generals a dit tractament.</p>
							<p className="p3"><b>10 EN RELACIÓ AMB LES DADES FACILITADES PER L’USUARI O INTERESSAT A L’APARTAT TREBALLA AMB NOSALTRES</b></p>
							<p className="p1">Així mateix, CZFB recull determinades dades a l’apartat de la web “Treballa amb Nosaltres” relacionats amb l’àrea de recursos humans amb la finalitat de procedir a la selecció de futurs treballadors i distribuïdors de l’empresa. En tot cas, les dades recollides i tractades pel CZFB són únicament de caràcter bàsic i són els justos i necessaris per a la finalitat assenyalada anteriorment.</p>
							<p className="p1">Si té alguna pregunta sobre aquesta Política de Privacitat, preguem que es posi en contacte amb nosaltres enviant un email a <span className="s1"><a href="mailto:czfb@zfbarcelona.es" target="_blank" rel="noreferrer">czfb@zfbarcelona.es</a></span> .</p>
						</>

					if (lang === 'en')
			txt = <>
							<h1>Privacy Policy and GDPR</h1>
							<p className="p1">Pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, we inform you that the Data Controller responsible for the processing of personal data on this site is:</p>
							<p className="p1">Consorci Zona Franca de Barcelona (CZFB)</p>
							<p className="p1">Av. Parc Logístic, 2-10, 08040 BARCELONA</p>
							<p className="p1">NIF:Q0876006H</p>
							<p className="p1">Tel: + 3493263811 E-mail: <a href="mailto:czfb@zfbarcelona.es">czfb@zfbarcelona.es</a></p>
							<p className="p1">DPD identification: mail: <a href="mailto:DPD@zfbarcelona.es">DPD@zfbarcelona.es</a></p>
							<p className="p1">(CZFB) is particularly sensitive with respect to the protection of the personal data of the Users of the services of this Website. By Privacy Policy (or Data Protection Policy) (CZFB) informs USERS of the websites <a href="https://zfbarcelona.es" target="_blank" rel="noreferrer">https://zfbarcelona.es</a>, of how their personal data collected through the Website may be used, so that they can freely and voluntarily decide whether they wish to provide the information requested.</p>
							<p className="p1">(CZFB) reserves the right to modify this Policy with the aim of adapting it to new legislation, case law, practice in the sector and the company’s interests. Any change in this Policy shall be announced with due notice, to ensure you are fully informed of its content.</p>
							<p className="p3"><b>1 PURPOSE OF THE PROCESSING</b></p>
							<p className="p1">The purpose of collecting and processing the personal data through the different forms (CZFB) made available to Usersis (depending on the specific case) to manage and respond to requests for information, doubts, complaints or suggestions made to the publications or any services or activities, acts or events provided, offered, sponsored and/or supported by (CZFB), as well as discovering the opinion of users; and also to manage job applications by receiving CVs, contacting with the interested parties and organising the selection process.</p>
							<p className="p3"><b>2 STORAGE TIME</b></p>
							<p className="p1">The personal data provided will be stored for the corresponding period to comply with legal obligations, or if its deletion is requested by the data subject that is authorised to do so.</p>
							<p className="p3"><b>3 AUTHORISATION</b></p>
							<p className="p1">The (CZFB) is authorised to process the persona data based on the consent granted by the data subject for one or more specific purposes, under article 6.1 a) of Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons.</p>
							<p className="p3"><b>4 ACCURACY OF DATA</b></p>
							<p className="p1">In addition, to ensure that the data in our files, computers and/or on paper are always accurate, we will aim to keep them up to date at all times. Users must therefore make any changes directly, when this possibility is made available, or by notifying means the corresponding area or department of (CZFB) in a verifiable manner.</p>
							<p className="p3"><b>5 ADDRESSEES</b></p>
							<p className="p1">The personal data may not be transferred or notified to third parties, except for cases necessary for the development, control and compliance with the purpose(s) expressed, in the cases provided for by law.</p>
							<p className="p3"><b>6 RIGHTS OF USERS</b></p>
							<p className="p1">However, the data subjects may in all cases exercise their rights under the General Data Protection Regulation, specifically:</p>
							<p className="p1">•	the right to request access to personal data related to the data subject;</p>
							<p className="p1">•	the right to request their correction or deletion;</p>
							<p className="p1">•	the right to request a restriction to its processing;</p>
							<p className="p1">•	the right to object to its processing;</p>
							<p className="p1">•	the right to data portability.</p>
							<p className="p1">The data subject may exercise these rights by means of a request accompanied by a photocopy of his or her DNI [ID] and specifying which of these rights they wish to exercise, to the following address: (CZFB), with registered address for these purposes at Av. Parc Logístic, 2-10, 08040 Barcelona, Spain.</p>
							<p className="p1">If you consider your right to personal data protection to be violated, you may submit a complaint to the Spanish Data Protection Agency (<span className="s1"><a href="https://www.agpd.es" target="_blank" rel="noreferrer">www.agpd.es</a></span>).</p>
							<p className="p3"><b>7 SECURITY MEASURES</b></p>
							<p className="p1">Finally, you are informed that (CZFB) will implement the legally required technical and organisational measures in its system to guarantee the security and confidentiality of the data stored, to prevent its unauthorised alteration, loss, processing or access; with due regard to the state of the art, the cost of the application and the nature, scope, context and purposes of the processing, as well as the risks of varying likelihood and severity associated with each of the processes.</p>
							<p className="p3"><b>8 OBLIGATION OF ENTERING PERSONAL DATA</b></p>
							<p>It is not necessary to enter any personal data to access the (CZFB) website <a href="https://elconsorci.es" target="_blank" rel="noreferrer">https://elconsorci.es</a> However, to request information or apply for specific products or services, it may be necessary for the user, interested party or client to provide data in the forms available on the website for the purposes described above. The fields marked with an asterisk (*) are required. If they are not entered it will be impossible to send the request for information.</p>
							<p className="p3"><b>9 WITH RESPECT TO THE DATA PROVIDED BY THE USER OR CLIENT IN THE NEWSLETTER</b></p>
							<p className="p1">With respect to personal data provided by the user, interested party or client on the newsletter e-mail registration form, “Do you want us to call you?” and “Contacts”, these comply strictly with current law under Law 15/1999 on Personal Data Protection and its implementing Royal Decree 1720/2007. The CZFB informs users, interested parties or clients that the above data shall be included in a file system for automated processing and to answer applications for information or contact; and the users, interested parties or clients will provide their consent by accepting the general terms and conditions of this processing.</p>
							<p className="p3"><b>10 WITH RESPECT TO THE DATA PROVIDED BY THE USER OR DATA SUBJECT IN THE “WORK WITH US” SECTION</b></p>
							<p className="p1">The CZFB also collects certain data in the website section “Work with Us”, related to the human resources area, for the purpose of selecting future employees and distributors for the company. The data collected and processed by CZFB are in any case only basic in nature and are sufficient and necessary for the purpose outlined above.</p>
							<p className="p1">For any questions with respect to this Privacy Policy, please contact us by e-mail at <span className="s1"><a href="mailto:czfb@zfbarcelona.es" target="_blank" rel="noreferrer">czfb@zfbarcelona.es</a></span> .</p>
						</>
	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'POLITICAPRIVACIDAD.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'POLITICAPRIVACIDAD.DESCRIPTIONSEO' }) || ''}
		/>
	<div className="container-fluid px-3 px-lg-5 pb-5 secondary-pages">
			<div className="row">
				<div className="col-12">
					{txt}
				</div>
			</div>
		</div>
	</>
    	)
}

export default injectIntl(PoliticaPrivacidad)
