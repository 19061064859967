/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { FormattedMessage, injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useLayoutEffect } from "react";
import clsx from "clsx";
import { Link, Outlet, useLocation } from "react-router-dom";
import logo from "./assets/logo.svg";
import logowhite from "./assets/logo-white.svg";
import logozf from "./assets/logo-zf.svg";
import btnmenu from "./assets/bt-menu.svg";
import btnclose from "./assets/bt-close.svg";
import videoheader from "./assets/videoheader.webm";
import videoheadermp4 from "./assets/videoheader.mp4";
import { useTexts } from "./hooks/useTexts";
import {
	setLanguage,
	setLanguageHome,
	getConfig,
	useLang,
} from "./translations/i18n";

import RSSMenu from "./components/layout/RSSMenu";
import MenuFooter from "./components/layout/MenuFooter";
import RSS from "./components/layout/RSS";

import { RUTAS } from "./constants/routes.constants";
import TranslatedLink from "./components/navigation/TranslatedLink";
import ScrollToTop from "./components/ScrollToTop";
import {
	CookieConsentBanner,
	triggerCookieConsentBanner,
} from "@porscheofficial/cookie-consent-banner-react";

/*function getEmptyReceiver() {
	return {
		email: '',
		role: 'receiver'
	}
}*/

const Top = ({
	handleClick,
	menuOpen,
	pathname,
	handleChangeHome,
	handleChange,
}) => (
	<div className="row py-5 px-3 px-lg-5">
		<div className="col col-md-auto">
			<TranslatedLink to={RUTAS.BASE}>
				<img src={logo} alt="The inspiring Hub" className="logo" />
			</TranslatedLink>
		</div>
		<div className="header__nav d-none d-md-flex col">
			<ul className="nav justify-content-end">
				<li className="nav-item"></li>
			</ul>
		</div>
		<div className="col-auto">
			<nav>
				<ul className="nav">
					<li onClick={(e) => handleClick(e, true)}>
						<img src={btnmenu} className="menubutton" alt="Menu" />
					</li>
				</ul>
			</nav>
			<div
				className={clsx(
					"menu align-content-between flex-wrap",
					menuOpen ? "active" : "inactive"
				)}
			>
				<div className="w-100">
					<div
						className="py-4 px-3 px-lg-5"
						style={{ textAlign: "right" }}
						onClick={(e) => handleClick(e, false)}
					>
						<img
							src={btnclose}
							className="menubuttonclose"
							alt="Menu"
						/>
					</div>
					<div className="pb-4 px-5 text-center text-lg-start">
						<TranslatedLink
							to={RUTAS.BASE}
							className={clsx(
								"px-4",
								pathname === `${RUTAS.BASE.es}` ||
									pathname === `${RUTAS.BASE.en}` ||
									pathname === `${RUTAS.BASE.ca}`
									? "fontbold"
									: ""
							)}
						>
							<FormattedMessage id="MENU.INICIO" />
						</TranslatedLink>
					</div>
					<div className="pb-4 px-5 text-center text-lg-start">
						<TranslatedLink
							to={RUTAS.VIDEOS}
							className={clsx(
								"px-4",
								pathname === `${RUTAS.VIDEOS.es}` ||
									pathname === `${RUTAS.VIDEOS.ca}` ||
									pathname === `${RUTAS.VIDEOS.en}`
									? "fontbold"
									: ""
							)}
						>
							<FormattedMessage id="MENU.VIDEOS" />
						</TranslatedLink>
					</div>
					<div className="pb-4 px-5 text-center text-lg-start">
						<TranslatedLink
							to={RUTAS.PODCASTS}
							className={clsx(
								"px-4",
								pathname === `${RUTAS.PODCASTS.es}` ||
									pathname === `${RUTAS.PODCASTS.ca}` ||
									pathname === `${RUTAS.PODCASTS.en}`
									? "fontbold"
									: ""
							)}
						>
							<FormattedMessage id="MENU.PODCASTS" />
						</TranslatedLink>
					</div>
					<div className="pb-4 px-5 text-center text-lg-start">
						<TranslatedLink
							to={RUTAS.ABOUT}
							className={clsx(
								"px-4",
								pathname === `${RUTAS.ABOUT.es}` ||
									pathname === `${RUTAS.ABOUT.ca}` ||
									pathname === `${RUTAS.ABOUT.en}`
									? "fontbold"
									: ""
							)}
						>
							<FormattedMessage id="MENU.THEINSPIRINGHUB" />
						</TranslatedLink>
					</div>
				</div>
				<div className="w-100">
					<hr />
					{pathname === `${RUTAS.BASE.es}` ||
					pathname === `${RUTAS.BASE.en}` ||
					pathname === `${RUTAS.BASE.ca}` ? (
						<div className="py-2 px-5 text-center text-lg-start">
							<div className="languages px-0 px-lg-4">
								<a
									href={
										window.location.host === "92.222.217.72"
											? "/hubdigital/"
											: "/"
									}
									onClick={handleChangeHome("es")}
									className={
										getConfig().selectedLang === "es"
											? "fontbold"
											: ""
									}
								>
									ESP
								</a>{" "}
								<a className="mx-3">|</a>{" "}
								<a
									href={
										window.location.host === "92.222.217.72"
											? "/hubdigital/en"
											: "/en"
									}
									onClick={handleChangeHome("en")}
									className={
										getConfig().selectedLang === "en"
											? "fontbold"
											: ""
									}
								>
									ENG
								</a>{" "}
								<a className="mx-3">|</a>{" "}
								<a
									href={
										window.location.host === "92.222.217.72"
											? "/hubdigital/ca"
											: "/ca"
									}
									onClick={handleChangeHome("ca")}
									className={
										getConfig().selectedLang === "ca"
											? "fontbold"
											: ""
									}
								>
									CAT
								</a>
							</div>
						</div>
					) : (
						<div className="py-2 px-5 text-center text-lg-start">
							<div className="languages px-0 px-lg-4">
								<span
									onClick={handleChange("es")}
									className={
										getConfig().selectedLang === "es"
											? "fontbold"
											: ""
									}
								>
									ESP
								</span>{" "}
								<span className="mx-3">|</span>{" "}
								<span
									onClick={handleChange("en")}
									className={
										getConfig().selectedLang === "en"
											? "fontbold"
											: ""
									}
								>
									ENG
								</span>{" "}
								<span className="mx-3">|</span>{" "}
								<span
									onClick={handleChange("ca")}
									className={
										getConfig().selectedLang === "ca"
											? "fontbold"
											: ""
									}
								>
									CAT
								</span>
							</div>
						</div>
					)}
					<hr />
				</div>
				<div className="pt-2 pb-4 px-5 w-100">
					<RSSMenu />
				</div>
			</div>
		</div>
	</div>
);

function gtag() {
	window.dataLayer = window.dataLayer || [];
	window.PageDataLayer = window.PageDataLayer || [];

	window.dataLayer.push(arguments);
	window.PageDataLayer.push(arguments);
}

function consentGranted() {
	gtag("consent", "update", {
		ad_storage: "granted",
		ad_user_data: "granted",
		ad_personalization: "granted",
		analytics_storage: "granted",
	});
}

function defaultConsent() {
	gtag("consent", "default", {
		ad_storage: "granted",
		ad_user_data: "denied",
		ad_personalization: "denied",
		analytics_storage: "granted",
	});
}

function loadTagManager(cookieValue) {
	//if (typeof google_tag_manager !== "undefined") return; // load only once
	if (
		cookieValue.includes("analytics") ||
		cookieValue.includes("marketing")
	) {
		const gTags = function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
				"gtm.start": new Date().getTime(),
				event: "gtm.js",
			});
			let f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.async = true;
			j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		};
		gTags(window, document, "script", "dataLayer", "GTM-KFT2GM6");
		gTags(window, document, "script", "PageDataLayer", "GTM-KFT2GM6");
		consentGranted();
		//gTags(window, document, "script", "dataLayer", "GTM-T2C69BR");
	}
}

function App({ intl }) {
	//const [receiver, setReceiver] = useState(getEmptyReceiver())
	const [text, setText] = useState(null);
	const { pathname } = useLocation();
	const lang = useLang();
	const [menuOpen, setMenuOpen] = useState(false);
	const { handleFetchText } = useTexts();
	const [acceptedCategories, setAcceptedCategories] = useState([]);
	const [showCookieBanner, setShowCookieBanner] = useState(false);
	const [acceptedCookies, setAcceptedCookies] = useState({
		necessary: true,
		analitycs: false,
		marketing: false,
	});

	const cookieValue = document.cookie;

	const ccButtons = document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelector(".cc_buttons");

	if (ccButtons && ccButtons?.childElementCount <= 2) {
		const newButton = document.createElement("button");

		newButton.setAttribute("type", "submit");

		newButton.classList.add("secondary");

		newButton.setAttribute(
			"style",
			"font-size: 11px; padding: 0.5rem 1.5rem; background-color: transparent; color: #fff; cursor: pointer;"
		);

		newButton.textContent = intl.formatMessage({
			id: "COOKIES.REJECTALL",
		});

		const secondButton = ccButtons.children[1];
		ccButtons.insertBefore(newButton, secondButton);

		newButton.addEventListener("click", function (event) {
			event.preventDefault();
			/*document
				.querySelector(".react-cookie")
				?.setAttribute("style", "display: none");*/
			document.cookie = "cookies_accepted_categories=";
			document.cookie = "show_cookies_banner=false";

			setShowCookieBanner(false);
			setAcceptedCategories([]);
		});
	}

	document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelector(".cc_settings")
		?.setAttribute("style", "padding-bottom: 0.5rem");

	document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelector(".cc_settings_description")
		?.setAttribute("style", "display: none");

	document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelectorAll(".cc_checkboxes_item")
		?.forEach((elem) =>
			elem.setAttribute("style", "padding-bottom: 0.3rem")
		);

	document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelector(".cc_text")
		?.setAttribute(
			"style",
			"font-size: 11px; padding-bottom: 0.5rem; padding-top: 0.5rem"
		);

	document
		.querySelector(".react-cookie")
		?.shadowRoot?.querySelectorAll("button")
		.forEach((elem) =>
			elem.setAttribute(
				"style",
				"font-size: 11px; padding: 0.5rem 1.5rem"
			)
		);

	if (ccButtons && ccButtons?.childElementCount <= 2) {
		const newButton = document.createElement("button");

		newButton.setAttribute("type", "submit");

		newButton.classList.add("secondary");

		newButton.setAttribute(
			"style",
			"font-size: 11px; padding: 0.5rem 1.5rem; background-color: transparent; color: #fff; cursor: pointer;"
		);

		newButton.textContent = intl.formatMessage({
			id: "COOKIES.REJECTALL",
		});

		const secondButton = ccButtons.children[1];
		ccButtons.insertBefore(newButton, secondButton);

		newButton.addEventListener("click", function (event) {
			event.preventDefault();
			/*document
					.querySelector(".react-cookie")
					?.setAttribute("style", "display: none");*/
			document.cookie = "cookies_accepted_categories=";
			document.cookie = "show_cookies_banner=false";

			setShowCookieBanner(false);
			setAcceptedCategories([]);
		});
	}

	useEffect(() => {
		const hideBanner = cookieValue
			.split("; ")
			.find((row) => row.startsWith("show_cookies_banner=false"));

		if (hideBanner) {
			setShowCookieBanner(false);
		}

		const cookies = cookieValue
			.split("; ")
			.find((row) => row.startsWith("cookies_accepted_categories="));

		if (cookies !== undefined && cookies?.includes("technically_required"))
			defaultConsent();

		if (cookies === undefined) {
			//triggerCookieConsentBanner({ showDetails: true });
			setShowCookieBanner(true);
		} else if (
			cookies?.includes("analytics") ||
			cookies?.includes("marketing")
		)
			loadTagManager(cookies);
	}, [cookieValue, showCookieBanner]);

	function handleClick(e, status) {
		e.stopPropagation();
		if (status) setMenuOpen(true);
		else setMenuOpen(false);
	}

	React.useEffect(() => {
		// eslint-disable-next-line
		const [_, url_lang] = pathname.split("/");
		if (url_lang === "ca" && lang !== "ca") setLanguage("ca");
		else if (url_lang === "en" && lang !== "en") setLanguage("en");
		else if (url_lang !== "ca" && url_lang !== "en" && lang !== "es")
			setLanguage("es");
	}, [lang, pathname]);

	React.useEffect(() => {
		const asyncFetchText = async () => {
			const { data } = await handleFetchText({
				id: "63d8da146efb29c596a4657f",
			});
			setText(data);
		};
		asyncFetchText();
		// eslint-disable-next-line
	}, [pathname]);

	const handleChange = (element) => (event) => {
		setLanguage(element, pathname);
	};

	const handleChangeHome = (element) => () => {
		setLanguageHome(element);
	};

	/*const handleChangeReceiver = (element) => (event) => {
		setReceiver({ ...receiver, [element]: event.target.value })
	}

	function saveReceiver() {
		postUser(receiver).then(res => {
			if (res.status === 201)
				alertSuccess({ title: 'Guardado!', customMessage: intl.formatMessage({ id: 'HOME.TUEMAIL' }) })

		}).catch(error => {
			alertError({ error: error, customMessage: intl.formatMessage({ id: 'HOME.NOSEHAPODIDO' }) })
		})
	}*/

	let copyright = (
		<>
			The Inspiring Hub &copy; {new Date().getFullYear()}
			<br />
			<FormattedMessage id="FOOTER.TODOSLOSDERECHOS" />
		</>
	);

	//oldnewsletter es la forma correcta de integrar la captación de destinatarios, se substituye, provisionalmente, por iframes para cada idioma
	/*let oldNewsletter = <><input id="emailreceiver" type="text" placeholder={intl.formatMessage({ id: 'HOME.ESCRIBETUEMAIL' })} className="receiverinput"
		value={receiver.email}
		onChange={handleChangeReceiver('email')}
	/><button className={clsx('receiverbutton px-4', (navigator.userAgent.indexOf('Mac') === -1) ? '' : 'mac-os')} onClick={() => saveReceiver()}><FormattedMessage id='FOOTER.SUSCRIBIRSE' /></button>
	</>*/

	const CookieBanner = () => {
		return (
			<div style={{ left: 5 }} className={`banner-container`}>
				<div className={`banner-body p-2`}>
					<FormattedMessage id="COOKIES.FULLINFORMATION" />
					<a
						href={intl.formatMessage({
							id: "COOKIES.COOKIESPOLICYLINK",
						})}
					>
						<FormattedMessage id="COOKIES.COOKIESPOLICYBUTTON" />
					</a>
					.
					<div
						className="d-flex flex-column align-items-start my-2 px-2"
						style={{ fontSize: "10px" }}
					>
						<div className="d-flex">
							<input
								type="checkbox"
								className="me-2"
								checked={acceptedCookies.necessary}
								disabled
							></input>
							<div>
								{intl.formatMessage({
									id: "COOKIES.TECHNICALLYREQUIREDLABEL",
								})}
								:{" "}
								{intl.formatMessage({
									id: "COOKIES.TECHNICALLYREQUIREDDESCRIPTION",
								})}
							</div>
						</div>
						<div className="d-flex mt-1">
							<input
								type="checkbox"
								className="me-2"
								checked={acceptedCookies.analitycs}
								onClick={() =>
									setAcceptedCookies({
										...acceptedCookies,
										analitycs: !acceptedCookies.analitycs,
									})
								}
							></input>
							<div>
								{intl.formatMessage({
									id: "COOKIES.ANALYTICSLABEL",
								})}
								:{" "}
								{intl.formatMessage({
									id: "COOKIES.ANALYTICSDESCRIPTION",
								})}
							</div>
						</div>
						<div className="d-flex mt-1">
							<input
								type="checkbox"
								className="me-2"
								checked={acceptedCookies.marketing}
								onClick={() =>
									setAcceptedCookies({
										...acceptedCookies,
										marketing: !acceptedCookies.marketing,
									})
								}
							></input>
							<div>
								{intl.formatMessage({
									id: "COOKIES.MARKETINGLABEL",
								})}
								:{" "}
								{intl.formatMessage({
									id: "COOKIES.MARKETINGDESCRIPTION",
								})}
							</div>
						</div>
					</div>
					<div className="row py-2 px-3 px-md-4 d-flex justify-content-md-end justify-content-center">
						<button
							className="col-12 col-md-4 banner-button-secondary mb-2 mb-md-0"
							onClick={() => {
								document.cookie =
									"cookies_accepted_categories=technically_required";
								setShowCookieBanner(false);
							}}
						>
							<FormattedMessage id="COOKIES.ACCEPTONLYESSENTIALS" />
						</button>
						<button
							className="col-12 col-md-2 banner-button-secondary mb-2 mb-md-0"
							onClick={() => {
								document.cookie =
									"cookies_accepted_categories=";
								document.cookie = "show_cookies_banner=false";
								setShowCookieBanner(false);
							}}
						>
							<FormattedMessage id="COOKIES.REJECTALL" />
						</button>
						<button
							className="col-12 col-md-3 banner-button-primary mb-2 mb-md-0"
							onClick={() => {
								setAcceptedCookies({
									necessary: true,
									analitycs: true,
									marketing: true,
								});
								document.cookie =
									"cookies_accepted_categories=technically_required,analytics,marketing";
								setShowCookieBanner(false);
							}}
						>
							<FormattedMessage id="COOKIES.SELECTALLANDCONTINUE" />
						</button>
					</div>
				</div>
			</div>
		);
	};

	let Newsletter = <></>;

	if (lang === "es")
		Newsletter = (
			<iframe
				title="newsletter-es"
				src="https://crm.zfbarcelona.es/l/541322/2022-06-15/3h5b5td"
				className="iframenewsletter"
				width="70%"
				height="51"
				type="text/html"
				frameborder="0"
				allowTransparency="true"
				scrolling="no"
				style={{ border: "0" }}
			></iframe>
		);
	if (lang === "ca")
		Newsletter = (
			<iframe
				title="newsletter-ca"
				src="https://crm.zfbarcelona.es/l/541322/2022-06-15/3h5b61s"
				className="iframenewsletter"
				width="70%"
				height="51"
				type="text/html"
				frameborder="0"
				allowTransparency="true"
				scrolling="no"
				style={{ border: "0" }}
			></iframe>
		);
	if (lang === "en")
		Newsletter = (
			<iframe
				title="newsletter-en"
				src="https://crm.zfbarcelona.es/l/541322/2022-06-15/3h5b61l"
				className="iframenewsletter"
				width="70%"
				height="51"
				type="text/html"
				frameborder="0"
				allowTransparency="true"
				scrolling="no"
				style={{ border: "0" }}
			></iframe>
		);

	return (
		<div className="App" onClick={(e) => handleClick(e, false)}>
			{pathname === `${RUTAS.BASE.es}` ||
			pathname === `${RUTAS.BASE.en}` ||
			pathname === `${RUTAS.BASE.ca}` ? (
				<header className="headerhome" style={{ position: "relative" }}>
					<video
						loop
						autoPlay
						muted
						playsInline
						className="videohome"
					>
						<source src={videoheader} type="video/webm" />
						<source src={videoheadermp4} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="container-fluid">
						<Top
							handleClick={handleClick}
							menuOpen={menuOpen}
							pathname={pathname}
							handleChangeHome={handleChangeHome}
							handleChange={handleChange}
						/>
						<div
							style={{
								position: "absolute",
								bottom: "120px",
								width: "100vw",
							}}
							className="text-center animated animatedFadeInUp fadeInUp col-lg-5 col-md-6 col-10"
						>
							<div
								className="introduction-title"
								dangerouslySetInnerHTML={{
									__html:
										text &&
										text.content &&
										text.content[lang],
								}}
							/>
						</div>
						<div
							id="scroll-down"
							style={{
								position: "absolute",
								bottom: "0px",
								left: "50%",
							}}
						>
							<span class="arrow-down"></span>
						</div>
					</div>
				</header>
			) : (
				<header style={{ position: "relative" }}>
					<div className="container-fluid">
						<Top
							handleClick={handleClick}
							menuOpen={menuOpen}
							pathname={pathname}
							handleChangeHome={handleChangeHome}
							handleChange={handleChange}
						/>
					</div>
				</header>
			)}

			<Outlet />

			<ScrollToTop />

			<footer className="footer">
				<div className="container-fluid px-3 px-lg-5">
					<div className="row">
						<div className="col-12 col-lg-9 text-lg-start text-center">
							<Link to="/">
								<img
									src={logowhite}
									alt="The inspiring Hub"
									className="logo my-5"
								/>
							</Link>
							<div className="fontbook mb-1 font14rem">
								<FormattedMessage id="FOOTER.DEJAQUE" />
							</div>
							<div className="row">
								<div className="col-12 col-lg-9 d-flex align-items-start justify-content-center justify-content-lg-start">
									{Newsletter}
								</div>
								<div className="col-3 d-none d-lg-block">
									<img
										src={logozf}
										alt="Consorci Barcelona Zona Franca"
										className="logo"
									/>
								</div>
							</div>
							<div className="d-none d-lg-block">
								<div className="row my-5">
									<div className="col-2 col-xxl-3">
										<TranslatedLink
											to={RUTAS.SITEMAP}
											className="footerlinks"
										>
											<FormattedMessage id="FOOTER.SITEMAP" />
										</TranslatedLink>
									</div>
									<div className="col-2 offset-1 offset-xxl-0 col-xxl-3">
										<TranslatedLink
											to={RUTAS.LEGAL}
											className="footerlinks"
										>
											<FormattedMessage id="FOOTER.AVISOLEGAL" />
										</TranslatedLink>
									</div>
									<div className="col-2 offset-1 offset-xxl-0 col-xxl-3">
										<TranslatedLink
											to={RUTAS.PRIVACIDAD}
											className="footerlinks"
										>
											<FormattedMessage id="FOOTER.POLITICADEPRIVACIDAD" />
										</TranslatedLink>
									</div>
									<div className="col-2 offset-1 offset-xxl-0 col-xxl-3 px-xxl-5">
										<TranslatedLink
											to={RUTAS.COOKIES}
											className="footerlinks"
										>
											<FormattedMessage id="FOOTER.COOKIES" />
										</TranslatedLink>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-3 borderleft py-5 d-none d-lg-block mt-3">
							<MenuFooter />
							<div className="w-100 footer-languages">
								<hr />
								{pathname === `${RUTAS.BASE.es}` ||
								pathname === `${RUTAS.BASE.en}` ||
								pathname === `${RUTAS.BASE.ca}` ? (
									<div className="py-2 text-center text-lg-start">
										<div className="languages px-0 px-xl-2">
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/"
														: "/"
												}
												onClick={handleChangeHome("es")}
												className={
													getConfig().selectedLang ===
													"es"
														? "fontbold"
														: ""
												}
											>
												ESP
											</a>{" "}
											<a className="mx-2 mx-xl-3">|</a>{" "}
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/en"
														: "/en"
												}
												onClick={handleChangeHome("en")}
												className={
													getConfig().selectedLang ===
													"en"
														? "fontbold"
														: ""
												}
											>
												ENG
											</a>{" "}
											<a className="mx-2 mx-xl-3">|</a>{" "}
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/ca"
														: "/ca"
												}
												onClick={handleChangeHome("ca")}
												className={
													getConfig().selectedLang ===
													"ca"
														? "fontbold"
														: ""
												}
											>
												CAT
											</a>
										</div>
									</div>
								) : (
									<div className="py-2 text-center text-lg-start">
										<div className="languages px-0 px-xl-2">
											<span
												onClick={handleChange("es")}
												className={
													getConfig().selectedLang ===
													"es"
														? "fontbold"
														: ""
												}
											>
												ESP
											</span>{" "}
											<span className="mx-2 mx-xl-3">
												|
											</span>{" "}
											<span
												onClick={handleChange("en")}
												className={
													getConfig().selectedLang ===
													"en"
														? "fontbold"
														: ""
												}
											>
												ENG
											</span>{" "}
											<span className="mx-2 mx-xl-3">
												|
											</span>{" "}
											<span
												onClick={handleChange("ca")}
												className={
													getConfig().selectedLang ===
													"ca"
														? "fontbold"
														: ""
												}
											>
												CAT
											</span>
										</div>
									</div>
								)}
								<hr />
							</div>
							<div className="row mt-4">
								<RSS />
							</div>
							<div className="legaltext fontbook mt-5">
								{copyright}
							</div>
						</div>
						<div className="col-12 d-block d-lg-none text-center mt-5 borderbottom">
							<MenuFooter />
							<div className="w-100 footer-languages">
								<hr />
								{pathname === `${RUTAS.BASE.es}` ||
								pathname === `${RUTAS.BASE.en}` ||
								pathname === `${RUTAS.BASE.ca}` ? (
									<div className="py-2 text-center text-lg-start">
										<div className="languages px-0 px-xl-2">
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/"
														: "/"
												}
												onClick={handleChangeHome("es")}
												className={
													getConfig().selectedLang ===
													"es"
														? "fontbold"
														: ""
												}
											>
												ESP
											</a>{" "}
											<a className="mx-2 mx-xl-3">|</a>{" "}
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/en"
														: "/en"
												}
												onClick={handleChangeHome("en")}
												className={
													getConfig().selectedLang ===
													"en"
														? "fontbold"
														: ""
												}
											>
												ENG
											</a>{" "}
											<a className="mx-2 mx-xl-3">|</a>{" "}
											<a
												href={
													window.location.host ===
													"92.222.217.72"
														? "/hubdigital/ca"
														: "/ca"
												}
												onClick={handleChangeHome("ca")}
												className={
													getConfig().selectedLang ===
													"ca"
														? "fontbold"
														: ""
												}
											>
												CAT
											</a>
										</div>
									</div>
								) : (
									<div className="py-2 text-center text-lg-start">
										<div className="languages px-0 px-xl-2">
											<span
												onClick={handleChange("es")}
												className={
													getConfig().selectedLang ===
													"es"
														? "fontbold"
														: ""
												}
											>
												ESP
											</span>{" "}
											<span className="mx-2 mx-xl-3">
												|
											</span>{" "}
											<span
												onClick={handleChange("en")}
												className={
													getConfig().selectedLang ===
													"en"
														? "fontbold"
														: ""
												}
											>
												ENG
											</span>{" "}
											<span className="mx-2 mx-xl-3">
												|
											</span>{" "}
											<span
												onClick={handleChange("ca")}
												className={
													getConfig().selectedLang ===
													"ca"
														? "fontbold"
														: ""
												}
											>
												CAT
											</span>
										</div>
									</div>
								)}
								<hr />
							</div>
							<div className="row px-4 pb-4">
								<RSS />
							</div>
						</div>
						<div className="col-12 text-center d-block d-lg-none">
							<img
								src={logozf}
								alt="Consorci Barcelona Zona Franca"
								className="logo py-4"
							/>
							<div className="row">
								<div className="col-6 my-2">
									<TranslatedLink
										to={RUTAS.SITEMAP}
										className="footerlinks"
									>
										<FormattedMessage id="FOOTER.SITEMAP" />
									</TranslatedLink>
								</div>
								<div className="col-6 my-2">
									<TranslatedLink
										to={RUTAS.LEGAL}
										className="footerlinks"
									>
										<FormattedMessage id="FOOTER.AVISOLEGAL" />
									</TranslatedLink>
								</div>
								<div className="col-6 my-2">
									<TranslatedLink
										to={RUTAS.PRIVACIDAD}
										className="footerlinks"
									>
										<FormattedMessage id="FOOTER.POLITICADEPRIVACIDAD" />
									</TranslatedLink>
								</div>
								<div className="col-6 my-2">
									<TranslatedLink
										to={RUTAS.COOKIES}
										className="footerlinks"
									>
										<FormattedMessage id="FOOTER.COOKIES" />
									</TranslatedLink>
								</div>
							</div>
							<div className="legaltext fontbook my-3">
								{copyright}
							</div>
						</div>
					</div>
				</div>
			</footer>

			{/*showCookieBanner && (
				<CookieConsentBanner
					disableResetSiteCookiesOnConsentWithdrawn
					handlePreferencesUpdated={({ acceptedCategories }) => {
						setShowCookieBanner(false);
						setAcceptedCategories(acceptedCategories);
					}}
					handlePreferencesRestored={({ acceptedCategories }) => {
						setShowCookieBanner(false);
						setAcceptedCategories(acceptedCategories);
					}}
					btnLabelAcceptAndContinue={intl.formatMessage({
						id: "COOKIES.AGREEANDCONTINUE",
					})}
					btnLabelSelectAllAndContinue={intl.formatMessage({
						id: "COOKIES.SELECTALLANDCONTINUE",
					})}
					btnLabelOnlyEssentialAndContinue={intl.formatMessage({
						id: "COOKIES.ACCEPTONLYESSENTIALS",
					})}
					btnLabelPersistSelectionAndContinue={intl.formatMessage({
						id: "COOKIES.ACCEPTONLYESSENTIALS",
					})}
					availableCategories={[
						{
							description: intl.formatMessage({
								id: "COOKIES.TECHNICALLYREQUIREDDESCRIPTION",
							}),
							key: "technically_required",
							label: intl.formatMessage({
								id: "COOKIES.TECHNICALLYREQUIREDLABEL",
							}),
							isMandatory: true,
						},
						{
							description: intl.formatMessage({
								id: "COOKIES.ANALYTICSDESCRIPTION",
							}),
							key: "analytics",
							label: intl.formatMessage({
								id: "COOKIES.ANALYTICSLABEL",
							}),
						},
						{
							description: intl.formatMessage({
								id: "COOKIES.MARKETINGDESCRIPTION",
							}),
							key: "marketing",
							label: intl.formatMessage({
								id: "COOKIES.MARKETINGLABEL",
							}),
						},
					]}
					className="react-cookie"
				>
					<FormattedMessage id="COOKIES.FULLINFORMATION" />
					<FormattedMessage id='COOKIES.INFORMATION' />
					<button
						onClick={() => {
							triggerCookieConsentBanner({ showDetails: true });
						}}
						type="button"
						>
						<FormattedMessage id='COOKIES.CONFIGURATIONBUTTON' />
					</button>
					<FormattedMessage id='COOKIES.MOREINFORMATION' />
					<a
						href={intl.formatMessage({
							id: "COOKIES.COOKIESPOLICYLINK",
						})}
					>
						<FormattedMessage id="COOKIES.COOKIESPOLICYBUTTON" />
					</a>
					.
				</CookieConsentBanner>
					)}*/}
			{showCookieBanner && <CookieBanner></CookieBanner>}
		</div>
	);
}

export default injectIntl(App);
