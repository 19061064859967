import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()

export const getPodcasts = async (tag, featured) => {
	let _endpoint = 'podcast?'
	if (tag)
		_endpoint = `${_endpoint}tag=${tag}&`
	if (featured)
		_endpoint = `${_endpoint}featured=${featured}&`

	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}

export const getPodcastsByTag = async (tags) => {
	const _endpoint = 'podcast/tag'
	const URL = `${API}/${_endpoint}?tags=${tags}`

	return instance.get(URL)
}

export const getPodcast = async (id) => {
	const _endpoint = `podcast/${id}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}

export const getPodcastByURL = async (url) => {
	const _endpoint = `podcast/url/${url}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}
