import { getText, getTexts } from '../api/text'
import { useMemo } from 'react'

export const useTexts = () => {

	return useMemo(() => {

		const handleFetchTexts = async ({ setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getTexts()

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data || []

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch texts ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchText = async ({ id, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getText(id)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
				/* gestionar el error */
				console.log('could not fetch text with id: ', id, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchTexts,
			handleFetchText
		}

	}, [])
}
