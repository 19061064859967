import React from 'react'
import { FormattedMessage } from 'react-intl'
import TranslatedLink from '../navigation/TranslatedLink'
import { RUTAS } from '../../constants/routes.constants'

function MenuFooter() {
	return (
		<>
			<div className="pb-4"><TranslatedLink to={RUTAS.VIDEOS} className="footerlinks"><FormattedMessage id='FOOTER.VIDEOS' /></TranslatedLink></div>
			<div className="pb-4"><TranslatedLink to={RUTAS.PODCASTS} className="footerlinks"><FormattedMessage id='FOOTER.PODCASTS' /></TranslatedLink></div>
			<div className="pb-4"><TranslatedLink to={RUTAS.ABOUT} className="footerlinks"><FormattedMessage id='FOOTER.THEINSPIRINGHUB' /></TranslatedLink></div>
		</>
	)
}

export default MenuFooter
