import React from 'react'
import icolinkedin from '../../assets/ico-linkedin.svg'
import icotwitter from '../../assets/ico-twitter.svg'
import icofacebook from '../../assets/ico-facebook.svg'
import icoyoutube from '../../assets/ico-youtube.svg'
import icoinstagram from '../../assets/ico-instagram.svg'

function RSSMenu() {
	return (
		<div className="d-flex flex-row rrssmenu">
			<div className="px-4 flex-fill"><a href="https://www.linkedin.com/company/consorci-de-la-zona-franca-de-barcelona/" alt="linkedin zonsorci de la zona franca" target="_blank" rel="noreferrer"><img src={icolinkedin} alt="Linkedin" className="ico"/></a></div>
			<div className="px-4 flex-fill"><a href="https://twitter.com/ConsorciZF" alt="twitter zonsorci de la zona franca" target="_blank" rel="noreferrer"><img src={icotwitter} alt="Twitter" className="ico"/></a></div>
			<div className="px-4 flex-fill"><a href="https://www.facebook.com/consorci.barcelona/" alt="facebook zonsorci de la zona franca" target="_blank" rel="noreferrer"><img src={icofacebook} alt="Facebook" className="ico"/></a></div>
			<div className="px-4 flex-fill"><a href="https://www.youtube.com/channel/UCwH18-Wt7-uhYWM4T6B06iA" alt="Youtube zonsorci de la zona franca" target="_blank" rel="noreferrer"><img src={icoyoutube} alt="Youtube" className="ico"/></a></div>
			<div className="px-4 flex-fill"><a href="https://www.instagram.com/zonafrancabarcelona/" alt="instagram zonsorci de la zona franca" target="_blank" rel="noreferrer"><img src={icoinstagram} alt="Instagram" className="ico"/></a></div>
		</div>
	)
}

export default RSSMenu
