import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTags } from '../../hooks/useTags'
import { SERVER_URL } from '../../utils'
import {useLang} from '../../translations/i18n'
import { RUTAS } from '../../constants/routes.constants'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'

const Blockright = ({elem1, elem2, elem3, typecontent}) => {
	const [dataTags, setDataTags] = useState([])
	const { handleFetchTags } = useTags()

  const lang = useLang()

	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
  });

  useEffect(() => {
    const asyncFetchTags = async () => {
      const dataTags = await handleFetchTags({ })
      setDataTags(dataTags)
    }
    asyncFetchTags()
	// eslint-disable-next-line
  }, [])

	return (
    <div className="row elementos my-2 my-lg-5">
      <div className="col-12 col-lg-7">
				{!!elem1 && <>
					<Link
						to={RUTAS[typecontent][lang].replace(':name', elem1?.seoURL?.es)}
	          state={elem1._id}>
						<div className="position-relative">
							<img src={`${SERVER_URL}/${elem1?.imagevert ? elem1.imagevert : elem1.image}`} alt={elem1.seotitle && elem1.seotitle[lang]} className="d-none d-lg-inline-flex img-fluid dark"/>
		          			<img src={`${SERVER_URL}/${elem1.image}`} alt={elem1.seotitle && elem1.seotitle[lang]} className="d-inline-flex d-lg-none img-fluid dark"/>
							<div className="playover">(PLAY)</div>
						</div>
		        <div className="row my-3">
		          <div className="d-flex flex-row mb-3" ref={ref}>
		            <div className="fontbook d-none d-lg-block">{elem1.duration[lang]}</div>
		            <div className={clsx('my-1 mx-4 d-none d-lg-block redline', inView ? 'redlineactive' : '')}></div>
		            <div className="px-lg-3 w-100">
		              <div className="fontmedium mb-3">{elem1.tags.map((elem) =>{
											return(
												dataTags.find(cat => cat._id === elem)?.fullName[lang] || '---'
											)
										}).join(' | ')}</div>
		              	{!!elem1.summary && <div className="fontlight fontsummary">{elem1.summary[lang]}</div>}
		            </div>
								<div className={clsx('redlineright', inView ? 'redlinerightactive' : '')}></div>
		          </div>
		        </div>
					</Link>
				</>}
        {!!elem3 && <>
				<div className="px-lg-5 py-4">
					<Link
						to={RUTAS[typecontent][lang].replace(':name', elem3?.seoURL?.es)}
	          state={elem3._id}>
						<div className="position-relative">
							<img src={`${SERVER_URL}/${elem3?.imagevert ? elem3.imagevert : elem3.image}`} alt={elem3.seotitle && elem3.seotitle[lang]} className="d-none d-lg-inline-flex img-fluid dark"/>
		          			<img src={`${SERVER_URL}/${elem3.image}`} alt={elem3.seotitle && elem3.seotitle[lang]} className="d-inline-flex d-lg-none img-fluid dark"/>
							<div className="playover">(PLAY)</div>
						</div>
	          <div className="row my-3">
	            <div className="d-flex flex-row mb-3">
	              <div className="fontbook d-none d-lg-block">{elem3.duration[lang]}</div>
	              <div className={clsx('my-1 mx-4 d-none d-lg-block redline', inView ? 'redlineactive' : '')}></div>
	              <div className="px-lg-3 w-100">
		              <div className="fontmedium mb-3">{elem3.tags.map((elem) =>{
											return(
												dataTags.find(cat => cat._id === elem)?.fullName[lang] || '---'
											)
										}).join(' | ')}</div>
									{!!elem3.summary && <div className="fontlight fontsummary">{elem3.summary[lang]}</div>}
	              </div>
								<div className={clsx('redlineright', inView ? 'redlinerightactive' : '')}></div>
	            </div>
	          </div>
					</Link>
        </div>
				</>}
      </div>
			{!!elem2 && <>
      <div className="col-12 col-lg-5 fontlight d-flex align-content-center flex-wrap">
        <div className="destacado2 my-5 my-lg-0">
					<Link
						to={RUTAS[typecontent][lang].replace(':name', elem2?.seoURL?.es)}
	          state={elem2._id}>
						<div className="position-relative">
							<img src={`${SERVER_URL}/${elem2?.imagevert ? elem2.imagevert : elem2.image}`} alt={elem2.seotitle && elem2.seotitle[lang]} className="d-none d-lg-inline-flex img-fluid dark"/>
		          			<img src={`${SERVER_URL}/${elem2.image}`} alt={elem2.seotitle && elem2.seotitle[lang]} className="d-inline-flex d-lg-none img-fluid dark"/>
							<div className="playover">(PLAY)</div>
						</div>
	          <div className="d-flex flex-row mb-3 my-3">
	            <div className="fontbook d-none d-lg-block">{elem2.duration[lang]}</div>
	            <div className={clsx('my-1 mx-4 d-none d-lg-block redline', inView ? 'redlineactive' : '')}></div>
	            <div className="px-lg-3 w-100">
	              <div className="fontmedium mb-3">{elem2.tags.map((elem) =>{
										return(
											dataTags.find(cat => cat._id === elem)?.fullName[lang] || '---'
										)
									}).join(' | ')}</div>
								{!!elem2.summary && <div className="fontlight fontsummary">{elem2.summary[lang]}</div>}
	            </div>
							<div className={clsx('redlineright', inView ? 'redlinerightactive' : '')}></div>
	          </div>
					</Link>
        </div>
      </div>
			</>}
    </div>
	)
}
export default Blockright
