import { getVideo, getVideos, getVideosByTag, getVideoByURL } from '../api/video'
import { useMemo } from 'react'

export const useVideos = () => {

	return useMemo(() => {

		const handleFetchVideos = async ({ featured, tag, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getVideos(tag, featured)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data || []

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch videos ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchVideosByTag = async ({ tags, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getVideosByTag(tags)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch videos ', error)
				return []
			} finally {
				setLoading(false)
			}
		}

		const handleFetchVideoURL = async ({ url, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getVideoByURL(url)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
				/* gestionar el error */
				console.log('could not fetch video with url: ', url, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchVideo = async ({ id, setLoading = () => {} }) => {
			console.log('ID:', id)
			setLoading(true)

			try {
				const { data } = await getVideo(id)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
				/* gestionar el error */
				console.log('could not fetch video with id: ', id, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchVideos,
			handleFetchVideo,
			handleFetchVideosByTag,
			handleFetchVideoURL
		}

	}, [])
}
