import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'

function PoliticaCookies({ intl }) {

	const lang = useLang()


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	let txt = <>
							<h1>Política de cookies</h1>
							<p>Este sitio web, al igual que la mayoría de los sitios en Internet, usa&nbsp;<strong>Cookies</strong>&nbsp;para mejorar y optimizar la experiencia del usuario.</p>
							<p>A continuación, encontrará información detallada sobre qué son las&nbsp;<strong>Cookies</strong>, qué tipo de&nbsp;<strong>Cookies</strong>&nbsp;utiliza este sitio web, cómo puede desactivarlas en su navegador y cómo bloquear específicamente la instalación de&nbsp;<strong>Cookies</strong>&nbsp;de terceros.</p>
							<p><strong>1. ¿QUÉ SON LAS COOKIES?</strong></p>
							<p>Las&nbsp;<strong>Cookies</strong>&nbsp;son archivos que los sitios web o las aplicaciones instalan en el navegador o en el dispositivo (smartphone, tablet o televisión conectada) del usuario durante su recorrido por las páginas del sitio o por la aplicación, y sirven para almacenar información sobre su visita.</p>
							<p>Como la mayoría de los sitios en internet, este sitio web utiliza&nbsp;<strong>Cookies</strong>&nbsp;para:</p>
							<ul>
								<li>Asegurar que las páginas web pueden funcionar correctamente.</li>
								<li>Almacenar las preferencias, como el idioma que ha seleccionado o el tamaño de letra.</li>
								<li>Conocer la experiencia de navegación del usuario.</li>
								<li>Recopilar información estadística anónima, como qué páginas ha visitado el usuario o cuánto tiempo ha permanecido en el sitio web.</li>
							</ul>
							<p>El uso de&nbsp;<strong>Cookies</strong>&nbsp;permite optimizar la navegación, adaptando la información y los servicios ofrecidos a los intereses del usuario, para proporcionarle una mejor experiencia siempre que visita el sitio web.</p>
							<p>Este sitio web utiliza&nbsp;<strong>Cookies</strong>&nbsp;para funcionar, adaptar y facilitar al máximo la navegación del usuario.</p>
							<p><strong>2. TIPOLOGÍA, FINALIDAD Y FUNCIONAMIENTO</strong></p>
							<p>Las&nbsp;<strong>Cookies</strong>, en función de su permanencia, pueden dividirse en&nbsp;<strong>cookies de sesión</strong>&nbsp;o&nbsp;<strong>permanentes</strong>. Las primeras expiran cuando el usuario cierra el navegador. Las segundas expiran cuando se cumple el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en el sitio web) o bien cuando se borran manualmente.</p>
							<p>Adicionalmente,&nbsp;<strong>en función de su objetivo</strong>, las&nbsp;<strong>Cookies</strong>&nbsp;pueden clasificarse de la siguiente forma:</p>
							<ul>
								<li><strong>Estrictamente necesarias (técnicas)</strong>: Son aquellas imprescindibles para el correcto funcionamiento de la página. Normalmente se generan cuando el usuario accede al sitio web o inicia sesión en el mismo y se utilizan para identificarle en el sitio web con los siguientes objetivos:</li>
								<li>Mantener al usuario identificado de forma que, si abandona el sitio web, el navegador o el dispositivo y en otro momento vuelve a acceder a dicho sitio web, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse.</li>
								<li>Comprobar si el usuario está autorizado para acceder a ciertos servicios o zonas del sitio web.</li>
								<li><strong>De rendimiento</strong>: Se utilizan para mejorar la experiencia de navegación y optimizar el funcionamiento del sitio web, como por ejemplo, almacenar las configuraciones de servicios, o almacenar la compra realizada en un carrito de la compra.</li>
								<li><strong>De publicidad</strong>: Son aquellas que recaban información sobre los anuncios mostrados a los usuarios del sitio web. Pueden ser de dos tipos:</li>
								<li><strong>Anónimas:</strong>&nbsp;Solo recopilan información sobre los espacios publicitarios mostrados en el sitio web, con independencia del usuario que accede al sitio web, es decir, sin identificarle expresamente.</li>
								<li><strong>Personalizadas:</strong>&nbsp;Recopilan información personal del usuario del sitio web por parte de un tercero, para la personalización de dichos espacios publicitarios.</li>
								<li><strong>De geo-localización</strong>: Estas Cookies se utilizan para averiguar en qué país o región se encuentra el usuario cuando accede a un servicio del sitio web con el fin de ofrecerle contenidos o servicios adecuados a su localización.</li>
								<li><strong>De analíticas</strong>: Recopilan información sobre la experiencia de navegación del usuario en el sitio web, normalmente de forma anónima, aunque en ocasiones también permiten identificar de manera única e inequívoca al usuario con el fin de obtener informes sobre los intereses de los usuarios en los servicios que ofrece el sitio web.</li>
							</ul>
							<p><strong>3. COOKIES UTILIZADAS EN ESTE SITIO WEB</strong></p>
							<p>A continuación se muestra una tabla con las&nbsp;<strong>Cookies</strong>&nbsp;utilizadas en este sitio web, incorporando un criterio de “nivel de intrusividad” apoyado en una escala del 1 al 3, en la que:</p>
							<p><strong>Nivel 1</strong>: se corresponde con&nbsp;<strong>Cookies estrictamente necesarias</strong>&nbsp;para la prestación del propio servicio solicitado por el usuario.</p>
							<p><strong>Nivel 2</strong>: se corresponde con&nbsp;<strong>Cookies de rendimiento</strong>&nbsp;(anónimas) necesarias para el mantenimiento de contenidos y navegación, de las que solo es necesario informar sobre su existencia.</p>
							<p><strong>Nivel 3</strong>: se corresponde con&nbsp;<strong>Cookies gestionadas por terceros</strong>&nbsp;que permiten el seguimiento del usuario a través de webs de las que&nbsp;<strong>FACILSURE, S.L. </strong>&nbsp;no es titular. Normalmente se corresponden con&nbsp;<strong>Cookies de publicidad</strong>&nbsp;o de analíticas donde se identifica al usuario de manera única y evidente</p>
							<p><strong><u>Cookies técnicas</u></strong></p>
							<table width="582">
								<tbody>
									<tr>
										<td width="207"></td>
										<td width="168"><strong>Finalidad</strong></td>
										<td width="119"><strong>Proveedor</strong></td>
										<td width="88"><strong>Caducidad</strong></td>
									</tr>
									<tr>
										<td width="207">ads_consent</td>
										<td width="168">para aceptar/rechazar las cookies publicitarias</td>
										<td width="119"></td>
										<td width="88">12 meses</td>
									</tr>
									<tr>
										<td width="207">show_cookies_banner</td>
										<td width="168">Identificar si el usuario no desea que se le vuelva a mostrar el mensaje de uso de cookies</td>
										<td width="119">[Confirmar]</td>
										<td width="88">12 meses<p></p>
											<p>&nbsp;</p></td>
									</tr>
								</tbody>
							</table>
							<p><strong><u>Cookies analíticas&nbsp;</u></strong></p>
							<table width="623">
								<tbody>
									<tr>
										<td width="174"><strong>Proveedores e información de cookies analíticas</strong></td>
										<td width="329"><strong>Finalidad</strong></td>
										<td width="120"><strong>Caducidad</strong></td>
									</tr>
									<tr>
										<td width="174">&nbsp;<p></p>
											<p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noreferrer">Google Analytics</a>&nbsp;(cookies descritas a continuación)</p></td>
										<td colSpan="2" width="449">Google Analytics es una herramienta de analítica web que utiliza la información de las cookies para analizar el número de visitantes y de visitas en nuestro sitio web, la duración media de la visita, la media de páginas vistas por cada usuario, informes geográficos, sociodemográficos (en función del idioma, ubicación, proveedor de Internet, dispositivo móvil…).</td>
									</tr>
									<tr>
										<td width="174">_ga</td>
										<td width="329">Se usa para distinguir a los usuarios.</td>
										<td width="120"></td>
									</tr>
									<tr>
										<td width="174">_gid<p></p>
											<p>&nbsp;</p></td>
										<td width="329">Se usa para distinguir a los usuarios.<p></p>
											<p>&nbsp;</p></td>
										<td width="120">24 horas</td>
									</tr>
									<tr>
										<td width="174">_dc_gtm_&lt;property-id&gt;.</td>
										<td width="329">Se usa para limitar el porcentaje de solicitudes.</td>
										<td width="120">1 minuto</td>
									</tr>
									<tr>
										<td width="174">_gac_&lt;property-id&gt;<p></p>
											<p>&nbsp;</p></td>
										<td width="329">Incluye información de la campaña relativa al usuario.<p></p>
											<p>&nbsp;</p></td>
										<td width="120">90 días</td>
									</tr>
								</tbody>
							</table>
							<p>&nbsp;</p>
							<p><strong><u>Cookies publicitarias</u></strong></p>
							<table width="633">
								<tbody>
									<tr>
										<td width="173"><strong>Proveedores e información de cookies publicitarias</strong></td>
										<td width="320"><strong>Finalidad</strong></td>
										<td width="140"><strong>Duración</strong></td>
									</tr>
									<tr>
										<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Google Tag Manager)</td>
										<td width="320">Utilizamos la plataforma de Google para poder gestionar todas las cookies y demás tecnologías que tratan tu información en nuestro sitio web</td>
										<td width="140">13 meses</td>
									</tr>
									<tr>
										<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Doubleclick)</td>
										<td width="320">Utilizamos las cookies de Doubleclick para poder analizar tu navegación y ofrecerte noticias e información de EL CONSORCIO DE LA ZONA DE BARCELONA&nbsp; relevante.</td>
										<td width="140">13 meses</td>
									</tr>
								</tbody>
							</table>
							<p><strong>&nbsp;4. DESHABILITAR EL USO DE COOKIES</strong></p>
							<p>Si el usuario así lo desea, es posible dejar de aceptar las&nbsp;<strong>Cookies</strong>&nbsp;del navegador, o dejar de aceptar las&nbsp;<strong>Cookies</strong>&nbsp;de un servicio en particular.</p>
							<p>Todos los navegadores modernos permiten cambiar la configuración de Cookies. Estos ajustes normalmente se encuentran en las “Opciones” o “Preferencias” del menú del navegador.</p>
							<p>El usuario podrá, en cualquier momento, deshabilitar el uso de Cookies en este sitio web mediante:</p>
							<p>La configuración del&nbsp;<strong>navegador</strong>, por ejemplo:</p>
							<p><strong>Google Chrome</strong>:&nbsp;<a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647" target="_blank" rel="noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></p>
							<p><strong>Internet Explorer</strong>:&nbsp;<a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
							<p><strong>Mozilla Firefox</strong>:&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
							<p><strong>Apple Safari</strong>:&nbsp;<a href="http://support.apple.com/kb/ph5042" target="_blank" rel="noreferrer">http://support.apple.com/kb/ph5042</a></p>
							<p>Otras&nbsp;<strong>herramientas de terceros</strong>, disponibles on-line, que permiten a los usuarios detectar las&nbsp;<strong>Cookies</strong>&nbsp;en cada sitio web que visita y gestionar su desactivación (por ejemplo, Ghostery:&nbsp;<a href="http://www.ghostery.com/privacy-statement" target="_blank" rel="noreferrer">http://www.ghostery.com/privacy-statement</a>).</p>
							<p><strong>5. ¿QUÉ OCURRE AL DESHABILITAR LAS COOKIES?</strong></p>
							<p>Algunas funcionalidades y servicios pueden quedar deshabilitados o tener un comportamiento diferente al esperado como, por ejemplo, permanecer identificado, mantener las compras en el “carrito de la compra” en un servicio de comercio electrónico o recibir información dirigida a su localización, entre otros.</p>
							<p>Si deshabilita el uso de&nbsp;<strong>Cookies</strong>&nbsp;en este sitio web es probable que deje de poder acceder a ciertas zonas del mismo o que se degrade notablemente la experiencia de navegación en el mismo.</p>
							<p><strong>6. &nbsp;ACTUALIZACIÓN DE LA POLÍTICA DE COOKIES</strong></p>
							<p><strong>FACILSURE, S.L. </strong>&nbsp;puede modificar esta&nbsp;<strong>Política de cookies</strong>&nbsp;en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.</p>
							<p>Cuando se produzcan cambios significativos en esta&nbsp;<strong>Política de cookies</strong>, se comunicará a los usuarios bien mediante la web o a través de correo electrónico a los usuarios registrados.</p>
							<p><strong>AVISO LEGAL</strong></p>
							<p>Google Analytics</p>
							<p>Esta página web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal esta en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”).</p>
							<p>Google Analytics utiliza “cookies”, que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google.</p>
							<p>Puede Usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe Usted saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este website. Al utilizar este website Usted consiente el tratamiento de información acerca de Usted por Google en la forma y para los fines arriba indicados.</p>
							<p><a href="http://www.google.com/intl/es/policies/privacy/" target="_blank" rel="noreferrer">Política de Google&nbsp;</a></p>
							<p>Versión 2021</p>
						</>

	if (lang === 'ca')
						txt = <>
							<h1>Política de cookies</h1>
							<p>Aquest lloc web, com la majoria de llocs d’Internet, fa servir Cookies per millorar i optimitzar l’experiència de l’usuari.</p>
							<p>A continuació, trobarà informació detallada sobre què són les Cookies, quin tipus de Cookies utilitza aquest lloc web, com pot desactivar-les al seu navegador i com bloquejar específicament la instal·lació de Cookies de tercers.</p>
							<p><strong>1. QUÈ SON LES COOKIES?</strong></p>
							<p>Les Cookies són arxius que els llocs web o les aplicacions instal·len al navegador o al dispositiu (smartphone, tablet o televisió connectada) de l’usuari durant el seu recorregut per les pàgines del lloc o per l’aplicació, i serveixen per emmagatzemar informació sobre la seva visita.</p>
							<p>Com la majoria dels llocs d’internet, aquest lloc web utilitza Cookies per:</p>
							<ul>
								<li>Assegurar que les pàgines web poden funcionar correctament.</li>
								<li>Emmagatzemar les preferències, com l’idioma que ha seleccionat o la mida de lletra.</li>
								<li>Conèixer l’experiència de navegació de l’usuari.</li>
								<li>Recopilar informació estadística anònima, com quines pàgines ha visitat l’usuari o quant de temps s’ha mantingut al lloc web.</li>
							</ul>
							<p>L’ús de Cookies permet optimitzar la navegació, adaptant la informació i els serveis oferts als interessos de l’usuari, per proporcionar-li una millor experiència sempre que visita el lloc web.</p>
							<p>Aquest lloc web utilitza Cookies per funcionar, adaptar i facilitar al màxim la navegació de l’usuari.</p>
							<p><strong>2. TIPOLOGIA, FINALITAT I FUNCIONAMENT</strong></p>
							<p>Les Cookies, en funció de la seva permanència, poden dividir-se en cookies de sessió o permanents. Les primeres expiren quan l’usuari tanca el navegador. Les segones expiren quan es compleix l’objectiu pel qual serveixen (per exemple, perquè l’usuari es mantingui identificat al lloc web) o bé quan s’esborren manualment.</p>
							<p>Addicionalment, en funció del seu objectiu, les Cookies poden classificar-se de la següent manera:</p>
							<ul>
								<li>Estrictament necessàries (tècniques): Són aquelles imprescindibles pel correcte funcionament de la pàgina. Normalment es generen quan l’usuari accedeix al lloc web o inicia sessió al mateix, i s’utilitzen per identificar-lo al lloc web amb els següents objectius:</li>
								<li>Mantenir l’usuari identificat de manera que, si abandona el lloc web, el navegador o dispositiu i en un altre moment torna a accedir al lloc web, seguirà identificat, facilitant així la seva navegació sense haver de tornar a identificar-se.</li>
								<li>Comprovar si l’usuari està autoritzat per accedir a certs serveis o zones del lloc web.</li>
								<li>De rendiment: S’utilitzen per millorar l’experiència de navegació i optimitzar el funcionament del lloc web, com per exemple, emmagatzemar les configuracions de serveis, o emmagatzemar la compra realitzada en una cistella de compra.</li>
								<li>De publicitat: són aquelles que recullen informació sobre els anuncis mostrats als usuaris del lloc web. Poden ser de dos tipus:</li>
								<li>Anònimes: només recopilen informació sobre els espais publicitaris mostrats al lloc web, amb independència de l’usuari que accedeix al lloc web, és a dir, sense identificar-lo expressament.</li>
								<li>Personalitzades: recopilen informació personal de l’usuari del lloc web per part d’un tercer, per la personalització dels espais publicitaris esmentats.</li>
								<li>De geo-localització: Aquestes Cookies s’utilitzen per saber en quin país o regió es troba l’usuari quan accedeix a un servei del lloc web amb l’objectiu d’oferir-li continguts o serveis adequats a la seva localització.</li>
								<li>D’analítiques: Recopilen informació sobre l’experiència de navegació de l’usuari al lloc web, normalment de forma anònima, encara que a vegades també permeten identificar de manera única i inequívoca a l’usuari amb l’objectiu d’obtenir informes sobre els interessos dels usuaris als serveis que ofereix el lloc web.</li>
							</ul>
							<p><strong>3. COOKIES UTILITZADES EN AQUEST LLOC WEB</strong></p>
							<p>A continuació es mostra una taula amb les Cookies utilitzades en aquest lloc web, incorporant un criteri de “nivell d’intrusisme” basat en una escala de l’1 al 3, en la qual:</p>
							<p><strong>Nivell 1</strong>: es correspon amb Cookies estrictament necessàries per a la prestació del mateix servei sol·licitat per l’usuari.</p>
							<p><strong>Nivell 2</strong>: es correspon amb Cookies de rendiment (anònimes) necessàries pel manteniment de continguts i navegació, de les quals només és necessari informar sobre la seva existència.</p>
							<p><strong>Nivell 3</strong>: es correspon amb Cookies gestionades per tercers que permeten el seguiment de l’usuari a través de webs de les quals FACILSURE, S.L. no és titular. Normalment es corresponen amb Cookies de publicitat o d’analítiques on s’identifica a l’usuari de manera única i evident.</p>
							<p><strong><u>Cookies tècniques:</u></strong></p>
							<table width="582">
								<tbody>
									<tr>
										<td width="207"></td>
										<td width="168"><strong>Finalitat</strong></td>
										<td width="119"><strong>Proveïdor</strong></td>
										<td width="88"><strong>Caducitat</strong></td>
									</tr>
									<tr>
										<td width="207">ads_consent</td>
										<td width="168">per acceptar/rebutjar les cookies publicitàries</td>
										<td width="119"></td>
										<td width="88">12 mesos</td>
									</tr>
									<tr>
										<td width="207">show_cookies_banner</td>
										<td width="168">Identificar si l’usuari no vol que se li torni a mostrar el missatge d’ús de cookies</td>
										<td width="119">[Confirmar]</td>
										<td width="88">12 mesos<p></p>
											<p>&nbsp;</p></td>
									</tr>
								</tbody>
							</table>
							<p><strong><u>Cookies analíticas&nbsp;</u></strong></p>
							<table width="623">
								<tbody>
									<tr>
										<td width="174"><strong>Proveïdors i informació de cookies analítiques</strong></td>
										<td width="329"><strong>Finalitat</strong></td>
										<td width="120"><strong>Caducitat</strong></td>
									</tr>
									<tr>
										<td width="174">&nbsp;<p></p>
											<p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noreferrer">Google Analytics</a>&nbsp;(cookies descritas a continuación)</p></td>
										<td colSpan="2" width="449">Google Analytics es una herramienta de analítica web que utiliza la información de las cookies para analizar el número de visitantes y de visitas en nuestro sitio web, la duración media de la visita, la media de páginas vistas por cada usuario, informes geográficos, sociodemográficos (en función del idioma, ubicación, proveedor de Internet, dispositivo móvil…).</td>
									</tr>
									<tr>
										<td width="174">_ga</td>
										<td width="329">S'usa per distingir als usuaris.</td>
										<td width="120"></td>
									</tr>
									<tr>
										<td width="174">_gid<p></p>
											<p>&nbsp;</p></td>
										<td width="329">S'usa per distingir els usuaris.<p></p>
											<p>&nbsp;</p></td>
										<td width="120">24 hores</td>
									</tr>
									<tr>
										<td width="174">_dc_gtm_&lt;property-id&gt;.</td>
										<td width="329">S'usa per limitar el percentatge de sol·licituds.</td>
										<td width="120">1 minut</td>
									</tr>
									<tr>
										<td width="174">_gac_&lt;property-id&gt;<p></p>
											<p>&nbsp;</p></td>
										<td width="329">Inclou informació de la campanya relativa al usuari.<p></p>
											<p>&nbsp;</p></td>
										<td width="120">90 dies</td>
									</tr>
								</tbody>
							</table>
							<p>&nbsp;</p>
							<p><strong><u>Cookies publicitàries</u></strong></p>
							<table width="633">
								<tbody>
									<tr>
										<td width="173"><strong>Proveïdors i informació de cookies publicitàries</strong></td>
										<td width="320"><strong>Finalitat</strong></td>
										<td width="140"><strong>Durada</strong></td>
									</tr>
									<tr>
										<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Google Tag Manager)</td>
										<td width="320">Utilitzem la plataforma de Google per poder gestionar totes les cookies i altres tecnologies que tracten la teva informació al nostre lloc web</td>
										<td width="140">13 meses</td>
									</tr>
									<tr>
										<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Doubleclick)</td>
										<td width="320">Utilitzem les cookies de Doubleclick per poder analitzar la teva navegació i oferir-te notícies i informació del CONSORCI DE LA ZONA DE BARCELONA rellevant.</td>
										<td width="140">13 meses</td>
									</tr>
								</tbody>
							</table>
							<p><strong>&nbsp;4. DESHABILITAR L’ÚS DE COOKIES</strong></p>
							<p>Si l’usuari ho vol, es possible deixar d’acceptar les Cookies del navegador, o deixar d’acceptar les Cookies d’un servei en particular.</p>
							<p>Tots els navegadors moderns permeten canviar la configuració de Cookies. Aquests ajustos normalment es troben a “Opcions” o “preferències” del menú del navegador.</p>
							<p>L’usuari podrà, en qualsevol moment, deshabilitar l’ús de Cookies en aquest lloc web mitjançant:</p>
							<p>La configuració del navegador, per exemple:</p>
							<p><strong>Google Chrome</strong>:&nbsp;<a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647" target="_blank" rel="noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></p>
							<p><strong>Internet Explorer</strong>:&nbsp;<a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
							<p><strong>Mozilla Firefox</strong>:&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
							<p><strong>Apple Safari</strong>:&nbsp;<a href="http://support.apple.com/kb/ph5042" target="_blank" rel="noreferrer">http://support.apple.com/kb/ph5042</a></p>
							<p>Altres eines de tercers, disponibles on-line, que permeten als usuaris detectar les Cookies a cada lloc web que visita i gestionar la seva desactivació (per exemple, Ghostery: http://www.ghostery.com/privacy-statement).</p>
							<p><strong>5. QUÈ PASSA EN DESHABILITAR LES COOKIES?</strong></p>
							<p>Algunes funcionalitats i serveis poden quedar deshabilitats o tenir un comportament diferent de l’esperat com, per exemple, romandre identificat, mantenir les compres a la “cistella de la compra” en un servei de comerç electrònic o rebre informació dirigida a la seva localització, entre d’altres.</p>
							<p>Si deshabilita l’ús de Cookies d’aquest lloc web és probable que deixi de poder accedir a certes zones del mateix o que es degradi notablement l’experiència de navegació al mateix.</p>
							<p><strong>6. ACTUALIZACIÓ DE LA POLÍTICA DE COOKIES</strong></p>
							<p>FACILSURE, S.L. pot modificar aquesta Política de cookies en funció d’exigències legislatives, reglamentàries, o amb la finalitat d’adaptar la política esmentada a les instruccions dictades per l’Agència Espanyola de Protecció de Dades, pel qual s’aconsella als usuaris que la visitin periòdicament.</p>
							<p>Quan es produeixin canvis significatius en aquesta Política de cookies, es comunicarà als usuaris bé mitjançant la web o a través del correu electrònic als usuaris registrats.</p>
							<p><strong>AVÍS LEGAL</strong></p>
							<p>Google Analytics</p>
							<p>Aquesta pàgina web utilitza Google Analytics, un servei analític de web prestat per Google, Inc., una companyia de Delaware, l’oficina principal de la qual està a 1600 Amphitheater Parkway, Mountain View (California), CA 04043, Estats Units (“Google”).</p>
							<p>Google Analytics utilitza “cookies”, que són arxius de text ubicats al seu ordinador, per ajudar al website a analitzar l’ús que fan els usuaris del lloc web. La informació que genera la cookie sobre l’ús del website (inclosa la seva direcció IP) serà directament transmesa i arxivada per Google als servidors d’Estats Units. Google utilitzarà aquesta informació per compte nostre amb el propòsit de seguir la pista del seu ús del website, recopilant informes de l’activitat del website i prestant altres serveis relacionats amb l’activitat del website i l’ús d’Internet. Google podrà transmetre aquesta informació a tercers quan així ho requereixi la legislació, o quan aquests tercers processin la informació per compte de Google. Google no associarà la adreça IP amb cap altra dada de la qual disposi Google.</p>
							<p>Pot Vostè rebutjar el tractament de les dades o la informació rebutjant l’ús de cookies mitjançant la selecció de la configuració apropiada del seu navegador, tot i això, Vostè ha de saber que si ho fa pot ser que no es pugui usar la plena funcionalitat d’aquest website. En utilitzar aquest website, Vostè consent el tractament d’informació sobre Vostè per Google a la forma i pels objectius indicats a dalt.</p>
							<p><a href="http://www.google.com/intl/es/policies/privacy/" target="_blank" rel="noreferrer">Política de Google&nbsp;</a></p>
							<p>Versió 2021</p>
							</>

	if (lang === 'en')
							txt = <>
								<h1>Cookies Policy</h1>
								<p>This website, as is the case with most Internet sites, uses cookies to improve and optimise the user experience.</p>
								<p>Below is detailed information on what cookies are, what type of cookies are used on this website, how you can disable them in the browser and how specifically to block the installation of third-party cookies.</p>
								<p><strong>1. WHAT ARE COOKIES?</strong></p>
								<p>Cookies are files that websites or apps install in your browser or device (smartphone, tablet or Internet-connected TV) as you navigate the pages on the site or the app. They are used to store information about your visit.</p>
								<p>As is the case with most sites on the Internet, this website uses cookies to:</p>
								<ul>
									<li>Ensure that the website pages function correctly</li>
									<li>Store your preferences, such as the language selected or the font size;</li>
									<li>Get information about your browsing experience;</li>
									<li>Collect anonymous statistical information, such as what pages you have visited or how much time you have remained on the website.</li>
								</ul>
								<p>Cookies are used to optimise browsing, adapting the information and services offered to your experience, to provide you with a better experience each time you visit the website.</p>
								<p>This website uses cookies to make sure that browsing functions, adapt it and make it as easy as possible for users.</p>
								<p><strong>2. TYPE, PURPOSE AND OPERATION</strong></p>
								<p>Cookies may be divided by their permanence into session cookies or permanent cookies. Session cookies expire when the user closes the browser. Permanent cookies expire when their purpose has been completed (for example, keeping the user identified on the website) or when they are deleted manually.</p>
								<p>Cookies may also be classified according to their purpose, as follows</p>
								<ul>
									<li>Strictly necessary (technical): These are essential for the page to function correctly. Usually they are generated when the user accesses the website or logs in on it. They are used to identify the user on the website for the following purposes:</li>
									<li>To keep the user identified, so that if you leave the website, the browser or device and access it later, you will continue to be identified, making browsing easier without having to identify yourself again.</li>
									<li>To check whether you are authorised to access certain services or parts of the website.</li>
									<li>Performance: They are used to improve the browsing experience and optimise the operation of the website; for example, by storing the service settings, or keeping a purchase made in the online basket.</li>
									<li>Advertising: They collect information on the ads shown to users on the website. They may be of two types:</li>
									<li>Anonymous: They only collect information on advertising spaces shown on the website, regardless of the user who accesses the site, i.e. without identifying the user expressly.</li>
									<li>Personalised: They collect the user’s personal information from the website for a third party, to personalise these ads.</li>
									<li>Geolocation: These Cookies are used to determine what country or region the use is in when they access a website service, in order to offer them contents or services appropriate to the location in question.</li>
									<li>Analytical: They collect information about the user’s browsing experience on the website. This is normally done anonymously, but sometimes they also identify the user uniquely and unmistakeably in order to obtain reports on the interest shown in the services offered by the website.</li>
								</ul>
								<p><strong>3. COOKIES USED ON THIS WEBSITE</strong></p>
								<p>Below is a table showing the cookies used on this website. It includes a “level of intrusiveness” using a scale of 1 to 3, in which:</p>
								<p><strong>Level 1</strong>: corresponds to cookies that are strictly necessary to provide the service requested by the user.</p>
								<p><strong>Level 2</strong>: corresponds to performance cookies (anonymous), necessary to maintain content and browsing, which only have to give information about their existence.</p>
								<p><strong>Level 3</strong>: corresponds to cookies managed by third parties, which track the user through websites not owned by FACILSURE, S.L. They are usually advertising or analytical cookies, in which the user is identified uniquely and unmistakeably.</p>
								<p><strong><u>Technical cookies:</u></strong></p>
								<table width="582">
									<tbody>
										<tr>
											<td width="207"></td>
											<td width="168"><strong>Purpose</strong></td>
											<td width="119"><strong>Supplier</strong></td>
											<td width="88"><strong>Caducitat</strong></td>
										</tr>
										<tr>
											<td width="207">ads_consent</td>
											<td width="168">to accept/reject advertising cookies</td>
											<td width="119"></td>
											<td width="88">12 months</td>
										</tr>
										<tr>
											<td width="207">show_cookies_banner</td>
											<td width="168">Identify whether the user does not want the message on cookie use to be shown</td>
											<td width="119">[Confirm]</td>
											<td width="88">12 months<p></p>
												<p>&nbsp;</p></td>
										</tr>
									</tbody>
								</table>
								<p><strong><u>Analytical cookies </u></strong></p>
								<table width="623">
									<tbody>
										<tr>
											<td width="174"><strong>Suppliers of and information on analytical cookies</strong></td>
											<td width="329"><strong>Purpose</strong></td>
											<td width="120"><strong>Caducitat</strong></td>
										</tr>
										<tr>
											<td width="174">&nbsp;<p></p>
												<p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noreferrer">Google Analytics</a>&nbsp;(cookies descritas a continuación)</p></td>
											<td colSpan="2" width="449">Google Analytics is a web analytics tool that uses information from cookies to analyse the number of visitors and visits on our website, their average duration, the average pages seen per user, and geographical and sociodemographic reports (depending on the language, location, Internet provider, mobile device…)</td>
										</tr>
										<tr>
											<td width="174">_ga</td>
											<td width="329">Used to distinguish the users</td>
											<td width="120"></td>
										</tr>
										<tr>
											<td width="174">_gid<p></p>
												<p>&nbsp;</p></td>
											<td width="329">Used to distinguish the users<p></p>
												<p>&nbsp;</p></td>
											<td width="120">24 hours</td>
										</tr>
										<tr>
											<td width="174">_dc_gtm_&lt;property-id&gt;.</td>
											<td width="329">Used to limit the percentage of requests.</td>
											<td width="120">1 minute</td>
										</tr>
										<tr>
											<td width="174">_gac_&lt;property-id&gt;<p></p>
												<p>&nbsp;</p></td>
											<td width="329">Includes campaign information related to users<p></p>
												<p>&nbsp;</p></td>
											<td width="120">90 days</td>
										</tr>
									</tbody>
								</table>
								<p>&nbsp;</p>
								<p><strong><u>Advertising cookies</u></strong></p>
								<table width="633">
									<tbody>
										<tr>
											<td width="173"><strong>Suppliers of and information on advertising cookies</strong></td>
											<td width="320"><strong>Purpose</strong></td>
											<td width="140"><strong>Duration</strong></td>
										</tr>
										<tr>
											<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Google Tag Manager)</td>
											<td width="320">We use the Google platform to manage all the cookies and other technologies that process your information on our website</td>
											<td width="140">13 months</td>
										</tr>
										<tr>
											<td width="173"><a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google</a>&nbsp;(Doubleclick)</td>
											<td width="320">We use Doubleclick cookies to analyse your browsing and offer you relevant news and information about THE CONSORCI IN THE BARCELONA AREA.</td>
											<td width="140">13 months</td>
										</tr>
									</tbody>
								</table>
								<p><strong>&nbsp;4. DISABLING COOKIES</strong></p>
								<p>If you wish, you can decide not to accept the browser’s cookies, or stop accepting cookies for one particular service.</p>
								<p>All modern browsers allow you to change your cookies settings. These adjustments are usually in the “Settings” or “Preferences” of the browser’s menu.</p>
								<p>Users can disable the use of cookies on this website at any time by:</p>
								<p>The browser settings, for example:</p>
								<p><strong>Google Chrome</strong>:&nbsp;<a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647" target="_blank" rel="noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></p>
								<p><strong>Internet Explorer</strong>:&nbsp;<a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
								<p><strong>Mozilla Firefox</strong>:&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
								<p><strong>Apple Safari</strong>:&nbsp;<a href="http://support.apple.com/kb/ph5042" target="_blank" rel="noreferrer">http://support.apple.com/kb/ph5042</a></p>
								<p>Other third-party tools available online allow users to detect the cookies on each website they visit and manage their disabling (for example, Ghostery http://www.ghostery.com/privacy-statement).</p>
								<p><strong>5. WHAT HAPPENS IF YOU DISABLE COOKIES?</strong></p>
								<p>Some functionalities and services may be disabled or behave differently than expected, such as remaining identified, keeping purchases in the basket of an e-commerce service, or receiving information related to your location, among others.</p>
								<p>If you disable cookies on this website, you will probably no longer have access to certain parts of it, or your browsing experience on it will be far worse.</p>
								<p><strong>6. UPDATES OF THE COOKIE POLICY</strong></p>
								<p>FACILSURE, S.L. may modify this Cookies Policy in response to legislative or regulatory demands, or in order to adapt the policy to instructions issued by the Spanish Data Protection Agency. You are therefore advised to check it from time to time.</p>
								<p>When there are significant changes to the Cookies Policy, you will be notified either via the website or by e-mail if you are a registered user.</p>
								<p><strong>LEGAL NOTICE</strong></p>
								<p>Google Analytics</p>
								<p>This website uses Google Analytics, a web analytics service offered by Google, Inc., a company incorporated in Delaware whose head office is at 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, United States (“Google”).</p>
								<p>Google Analytics uses cookies, which are text files located in your computer, to help the website analyse how users behave on the website. The information generated by a cookie about your website use (including your IP address) will be transferred and stored by Google on its servers in the United States. Google will use this information on our behalf to track your use of the website, creating activity logs on the website and offering other services related to the website activity and Internet use. Google may transfer this information to third parties when required by law, or when these third parties process the information on Google’s account. Google may not associate your IP address with any other data available to Google.</p>
								<p>You may reject the processing of your data or information by selecting the appropriate settings on your browser to reject the use of cookies. However, you should be aware that if you do so, you may not be able to use the website’s full functionality. By using this website you agree that Google may process information about you in the form and for the purposes indicated above.</p>
								<p><a href="http://www.google.com/intl/es/policies/privacy/" target="_blank" rel="noreferrer">Política de Google&nbsp;</a></p>
								<p>Versió 2021</p>
								</>
	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'POLITICACOOKIES.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'POLITICACOOKIES.DESCRIPTIONSEO' }) || ''}
		/>
	<div className="container-fluid px-3 px-lg-5 pb-5 secondary-pages">
			<div className="row">
				<div className="col-12">
					{txt}
				</div>
			</div>
		</div>
	</>
    	)
}

export default injectIntl(PoliticaCookies)
