export const RUTAS = {
	BASE: {
		es: '/',
		en: '/en',
		ca: '/ca'
	},
	ABOUT: {
		es: '/el-hub',
		en: '/en/el-hub',
		ca: '/ca/el-hub',
	},
	VIDEOS: {
		es: '/videos',
		en: '/en/videos',
		ca: '/ca/videos',
	},
	VIDEO: {
		es: '/video/:name',
		en: '/en/video/:name',
		ca: '/ca/video/:name',
	},
	PODCASTS: {
		es: '/podcasts',
		en: '/en/podcasts',
		ca: '/ca/podcasts',
	},
	PODCAST: {
		es: '/podcast/:name',
		en: '/en/podcast/:name',
		ca: '/ca/podcast/:name',
	},
	SITEMAP: {
		es: '/mapa-sitio',
		en: '/en/site-map',
		ca: '/ca/mapa-lloc'
	},
	LEGAL: {
		es: '/aviso-legal',
		en: '/en/legal-notice',
		ca: '/ca/avis-legal',
	},
	PRIVACIDAD: {
		es: '/politica-privacidad',
		en: '/en/privacy-policy',
		ca: '/ca/politica-privacitat',
	},
	COOKIES: {
		es: '/politica-cookies',
		en: '/en/cookies-policy',
		ca: '/ca/politica-cookies',
	}
}