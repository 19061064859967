import React from 'react'

function HrButton() {
	return (
		<>
			<hr class="hr1"/><hr class="hr2"/>
		</>
	)
}
export default HrButton
