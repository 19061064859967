import { getTags } from '../api/tag'
import { useMemo } from 'react'

export const useTags = () => {

	return useMemo(() => {

		const handleFetchTags = async ({ setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getTags()

				if (!data)
					return {}

				return data

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch tags ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchTags
		}

	}, [])
}
