import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { I18nProvider } from './translations/I18nProvider'
import { CustomI18nProvider } from './translations/i18n'
import Rutas from './Rutas'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KFT2GM6',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<CustomI18nProvider>
			<I18nProvider>
				<Rutas />
			</I18nProvider>
		</CustomI18nProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
