import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Videos from './pages/Videos'
import Video from './pages/Video'
import Podcasts from './pages/Podcasts'
import Podcast from './pages/Podcast'
import Sitemap from './pages/Sitemap'
import AvisoLegal from './pages/AvisoLegal'
import PoliticaPrivacidad from './pages/PoliticaPrivacidad'
import PoliticaCookies from './pages/PoliticaCookies'
import { RUTAS } from './constants/routes.constants'
import NotFound from './pages/Page404';

function Rutas() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Routes>
				<Route path="/" element={<App />}>

					<Route path={RUTAS.BASE.es} element={<Home /> } />
					<Route path={RUTAS.BASE.ca} element={<Home /> } />
					<Route path={RUTAS.BASE.en} element={<Home /> } />

					<Route path={RUTAS.ABOUT.es} element={<AboutUs />} />
					<Route path={RUTAS.ABOUT.ca} element={<AboutUs />} />
					<Route path={RUTAS.ABOUT.en} element={<AboutUs />} />

					<Route path={RUTAS.VIDEOS.es} element={<Videos />} />
					<Route path={RUTAS.VIDEOS.ca} element={<Videos />} />
					<Route path={RUTAS.VIDEOS.en} element={<Videos />} />

					<Route path={RUTAS.VIDEO.es} element={<Video />} />
					<Route path={RUTAS.VIDEO.ca} element={<Video />} />
					<Route path={RUTAS.VIDEO.en} element={<Video />} />

					<Route path={RUTAS.PODCASTS.es} element={<Podcasts />} />
					<Route path={RUTAS.PODCASTS.ca} element={<Podcasts />} />
					<Route path={RUTAS.PODCASTS.en} element={<Podcasts />} />

					<Route path={RUTAS.PODCAST.es} element={<Podcast />} />
					<Route path={RUTAS.PODCAST.ca} element={<Podcast />} />
					<Route path={RUTAS.PODCAST.en} element={<Podcast />} />

					<Route path={RUTAS.SITEMAP.es} element={<Sitemap />} />
					<Route path={RUTAS.SITEMAP.ca} element={<Sitemap />} />
					<Route path={RUTAS.SITEMAP.en} element={<Sitemap />} />

					<Route path={RUTAS.LEGAL.es} element={<AvisoLegal />} />
					<Route path={RUTAS.LEGAL.ca} element={<AvisoLegal />} />
					<Route path={RUTAS.LEGAL.en} element={<AvisoLegal />} />

					<Route path={RUTAS.PRIVACIDAD.es} element={<PoliticaPrivacidad />} />
					<Route path={RUTAS.PRIVACIDAD.ca} element={<PoliticaPrivacidad />} />
					<Route path={RUTAS.PRIVACIDAD.en} element={<PoliticaPrivacidad />} />

					<Route path={RUTAS.COOKIES.es} element={<PoliticaCookies />} />
					<Route path={RUTAS.COOKIES.ca} element={<PoliticaCookies />} />
					<Route path={RUTAS.COOKIES.en} element={<PoliticaCookies />} />

		      <Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default Rutas
