import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import icolinkedin from '../assets/ico-linkedin.svg'
import icotwitter from '../assets/ico-twitter.svg'
import icofacebook from '../assets/ico-facebook.svg'
import icowhatsapp from '../assets/ico-whatsapp.svg'
import quoteico from '../assets/quote-ico.svg'
import back from '../assets/back.svg'
import { usePodcasts } from '../hooks/usePodcasts'
import { useTags } from '../hooks/useTags'
import { SERVER_URL } from '../utils'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'
import { RUTAS } from '../constants/routes.constants'
import TranslatedLink from '../components/navigation/TranslatedLink'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

/*const TranscriptionElem = ({ minute, text }) => {
	return (
		<div className="row mt-5">
			<div className="col-12 col-lg-1 text-start text-lg-center">{minute}</div>
			<div className="col-12 col-lg-11">{text}</div>
		</div>
	)
}*/

const OtherElem = ({ tags, summary, image, duration, id }) => {
	const lang = useLang()
	return (
		<div className="col-12 col-lg-4">
			<Link
				to={RUTAS.PODCAST[lang].replace(':name', summary)}
				state={id}
			>
				<img src={`${SERVER_URL}/${image}`} alt="podcast" className="img-fluid imgrelated" />
				<div className="redlineright2">
					<div className="fontmedium">{tags}</div>
					<div className="fontbook d-block d-lg-none my-1">{duration}</div>
					<p>{summary}</p>
					<div className="fontbook d-none d-lg-block">{duration}</div>
				</div>
			</Link>
		</div>
	)
}

function Podcast({ intl }) {
	const { pathname } = useLocation()
	const [dataTags, setDataTags] = useState([])
	const [data, setData] = useState([])
	const [dataPodcasts, setDataPodcasts] = useState([])
	const location = useLocation()
	const navigate = useNavigate()
	const { handleFetchPodcast, handleFetchPodcastURL } = usePodcasts()
	const { handleFetchPodcastsByTag } = usePodcasts()
	const { handleFetchTags } = useTags()


	useEffect(() => {
		localStorage.setItem('seoURL', JSON.stringify(data?.seoURL || {}))
	}, [data?.seoURL])

	const lang = useLang()

	const id = location.state || null


	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 250)
		const asyncFetch = async () => {
			let { data, error } = await handleFetchPodcast({ id: id })
			if (error){
				const { data: data2, error: error2 } = await handleFetchPodcastURL({ url: pathname.split('/').pop() })
				if (error2){
					navigate("/404")
					return
				}
				data = data2
			}
			data.idivoox = 'audio_'+data.idpodcast
			data.urlivoox = 'https://www.ivoox.com/player_ej_'+data.idpodcast+'_6_1.html'
			setData(data)
		}
		const asyncFetchTags = async () => {
			const dataTags = await handleFetchTags({ })
			setDataTags(dataTags)
		}

		asyncFetch()
		asyncFetchTags()
		// eslint-disable-next-line
	}, [location?.pathname])

	useEffect(() => {
		if (!data.tags)
			return

		const asyncFetchPodcasts = async () => {
			const dataPodcasts = await handleFetchPodcastsByTag({ tags: data.tags })
			setDataPodcasts(dataPodcasts)
		}
		asyncFetchPodcasts()
		// eslint-disable-next-line
	}, [data?.tags, location?.pathname])

	/*let transcription = ''

	if (data?.transcriptions && Object.keys(data?.transcriptions).length !== 0)
		transcription = <div className="row my-5">
			<div className="col-12 text-center my-3">
				<h2><FormattedMessage id='PODCAST.TRANSCRIPCION' /></h2>
			</div>
			<div className="col-12">
				<div className="transcriptions px-2">
					{data?.transcriptions && data?.transcriptions[lang]?.map(elem =>
						<TranscriptionElem
							minute={elem.minute}
							text={elem.text}
						/>
					)}
				</div>
			</div>
		</div>*/


	return (
		<>
			<CustomHelmet
				title={(data?.seotitle && data?.seotitle[lang]) || ''}
				description={(data?.seodescription && data?.seodescription[lang]) || ''}
			/>
			<div className="container-fluid px-3 px-lg-5">
				<div className="row">
					<div className="col-12"><TranslatedLink to={RUTAS.PODCASTS} className="volver"><img src={back} alt="back-button" className="backbutton"/><FormattedMessage id='PODCAST.VOLVER' /></TranslatedLink></div>
				</div>
				<div className="row my-3">
					<div className="col-12"><img src={`${SERVER_URL}/${data.image}`} alt="main-img" className="img-fluid mainImage" /></div>
				</div>
				<div className="row mt-5">
					<div className="col-11">
						<h1>{data.summary && data.summary[lang]}</h1>
						<div className="d-flex flex-row quote my-4">
							<div><img src={quoteico} alt="quote" className="quoteico" /></div>
							<div><span className="quotetxt">{data.quote && data.quote[lang]}</span></div>
						</div>
						{data.content && <p dangerouslySetInnerHTML={{ __html: data.content[lang].replaceAll("<a ", "<a class='displayIB' ") }}></p>}
						{data.idpodcast && <div>
							<iframe id={data.idivoox} title="voox" frameBorder='0' allowFullScreen='' scrolling='no' height='200' style={{ 'width':'100%' }} src={data.urlivoox} loading='lazy'></iframe>
						</div>}
					</div>
					<div className="col-1 text-center redline redlineactive">
					<div>
							<TwitterShareButton
								url={window.location.href}
								title={(data.summary && data.summary[lang]) || 'Podcast Hubdigital'}
							>
								<img src={icotwitter} alt="Twitter" className="icovert my-3"/>
							</TwitterShareButton>
						</div>
						<div>
							<FacebookShareButton
								url={window.location.href}
								quote={(data.summary && data.summary[lang]) || 'Podcast Hubdigital'}
							>
								<img src={icofacebook} alt="Facebook" className="icovert my-3"/>
							</FacebookShareButton>
						</div>
						<div>
							<LinkedinShareButton
								url={window.location.href}
								title={(data.summary && data.summary[lang]) || 'Podcast Hubdigital'}
								summary={(data.summary && data.summary[lang]) || 'Podcast Hubdigital'}
							>
								<img src={icolinkedin} alt="Linkedin" className="icovert my-3"/>
							</LinkedinShareButton>
						</div>
						<div>
							<WhatsappShareButton
								url={window.location.href}
								title={(data.summary && data.summary[lang]) || 'Podcast Hubdigital'}
							>
								<img src={icowhatsapp} alt="Whatsapp" className="icovert my-3" style={{ 'width':'20px' }}/>
							</WhatsappShareButton>
						</div>
						{/* <div><a href="https://twitter.com/share?ref_src=twsrc%5Etfw" data-text={data.summary && data.summary[lang]} className="twitter-share-button" data-show-count="false" target="_blank" rel="noreferrer"><img src={icotwitter} alt="Twitter" className="icovert my-3"/></a></div>
						<div><a href="https://www.facebook.com/sharer.php?u="><img src={icofacebook} alt="Facebook" className="icovert my-3"/></a></div>
						<div><a href="https://www.linkedin.com/sharing/share-offsite/?url="><img src={icolinkedin} alt="Linkedin" className="icovert my-3"/></a></div>
						<div><a href={clsx('https://api.whatsapp.com/send?text=', data.summary && data.summary[lang])} target="_blank" data-action="share/whatsapp/share" rel="noreferrer"><img src={icowhatsapp} alt="Whatsapp" className="icovert my-3" style={{ 'width':'20px' }}/></a></div>
						<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
					</div>
				</div>
				{/*transcription*/}
				<div className="row my-5">
					<div className="col-12 text-center">
						<h2><FormattedMessage id='PODCAST.TAMBIEN' /></h2>
					</div>
				</div>
				<div className="row mb-5">
					<div className="col-12">
						<div className="alsointeresting px-0 px-lg-2">
							<div className="row my-3 mb-5">
								{dataPodcasts?.filter(podcast => podcast._id !== data._id).slice(0, 3).map(elem =>
									<OtherElem
										tags={elem.tags.map((elem) => {
											return (
												dataTags?.find(cat => cat._id === elem)?.fullName[lang] || '---'
											)
										}).join(' | ')}
										image={elem.image}
										summary={elem.summary && elem.summary[lang]}
										duration={elem.duration}
										id={elem._id}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
    	)
}

export default injectIntl(Podcast)
