import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import test1 from '../assets/test1.jpg'
import test3 from '../assets/test3.jpg'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useThehubs } from '../hooks/useThehubs'
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx'
import { useLang } from '../translations/i18n'

function AboutUs({ intl }) {
	const { handleFetchThehubs } = useThehubs()
	const [dataThehubs, setDataThehubs] = useState([])

	const lang = useLang()
	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
  });

	useEffect(() => {
		window.scrollTo(0, 0)
		const asyncFetchThehubs = async () => {
			const dataThehubs = await handleFetchThehubs({ })
			setDataThehubs(dataThehubs)
		}
		asyncFetchThehubs()
		// eslint-disable-next-line
	}, [dataThehubs?.fullName])

	let txt1 = <>
		<h2 className="fontbold"><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'ABOUTUS.ESELLUGAR' }) } } /></h2>
		<p className="my-4 my-lg5"><div dangerouslySetInnerHTML={ { __html: dataThehubs[0] && dataThehubs[0].fullName[lang] } } /></p>
	</>
	let txt2 = <>
		<div dangerouslySetInnerHTML={ { __html: dataThehubs[1] && dataThehubs[1].fullName[lang] } } />
	</>
	let txt3 = <>
		<h2 className="fontbold"><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'ABOUTUS.BIENVENIDO' }) } } /></h2>
		<div dangerouslySetInnerHTML={ { __html: dataThehubs[2] && dataThehubs[2].fullName[lang] } } />
	</>

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'ABOUTUS.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'ABOUTUS.DESCRIPTIONSEO' }) || ''}
		/>
	<div className="container-fluid px-3 px-lg-5">
			<div className="row my-5">
				<div className="col-12 col-lg-7">
					{txt1}
					<div className="px-lg-5">
						<img src={test3} alt="Test3" className="img-fluid px-lg-5"/>
						<div className="text-center mb-4">
							<div className="verticalline" ref={ref}><div className={clsx('line', inView ? 'active' : '')}></div></div>
						</div>
						<div className="py-4 d-block d-lg-none">
							{txt2}
						</div>
						<div className="px-lg-5 d-none d-lg-block">
							{txt3}
						</div>
					</div>
				</div>
				<div className="col-12 col-lg-5">
					<div className="verticalline2 d-none d-lg-block"><div className={clsx('line', inView ? 'active' : '')}></div></div>
					<div className="py-5 d-none d-lg-block">
						{txt2}
					</div>
					<img src={test1} alt="Test1" className="img-fluid px-lg-4"/>
					<div className="text-center mb-4 d-block d-lg-none">
						<div className="verticalline"><div className={clsx('line', inView ? 'active' : '')}></div></div>
					</div>
					<div className="px-lg-5 d-block d-lg-none">
						{txt3}
					</div>
				</div>
			</div>
		</div>
	</>
  	)
}

export default injectIntl(AboutUs)
