import React , { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Blockleft from '../components/blockleft'
import Blockright from '../components/blockright'
import { useTags } from '../hooks/useTags'
import { useVideos } from '../hooks/useVideos'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'
import Pagination from '../components/Pagination'

const TagElem = ({ name, id, onClick, active }) => {
	const style = active
		? { fontWeight: 'bolder', cursor: 'pointer' }
		: { cursor: 'pointer' }
	return ( <>
		<div className="px-1 red">|</div>
		<div className="px-1" style={style} onClick={() => onClick(id)} >{name}</div>
	</> )
}

function getEveryNth(arr, nth) {
	const result = []

	for (let i = 0; i < arr.length; i += nth)
		result.push(arr.slice(i, i+nth))


	return result
}

function Videos({ intl }) {
	const [activeTag, setActiveTag] = useState(null)
	const [dataTags, setDataTags] = useState([])
	const [data, setData] = useState([])
	const [currentData, setCurrentData] = useState([])
	const { handleFetchTags } = useTags()
	const { handleFetchVideos } = useVideos()

	const lang = useLang()


	useEffect(() => {
		window.scrollTo(0, 0)

		const asyncFetchTags = async () => {
			const dataTags = await handleFetchTags({ })
			setDataTags(dataTags)
		}
		const asyncFetch = async () => {
			const data = await handleFetchVideos({ })
			setData(getEveryNth(data, 3))
		}
		asyncFetchTags()
		asyncFetch()
		// eslint-disable-next-line
	}, [])

	/* filter by tag */
	const searchByTag = index => async id => {
		setActiveTag(index)
		const data = await handleFetchVideos({ tag: id })
		setData(getEveryNth(data, 3))
	}

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'VIDEOS.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'VIDEOS.DESCRIPTIONSEO' }) || ''}
		/>
		<div className="container-fluid px-3 px-lg-5">
			<div className="row my-2 my-lg-5">
				<div className="col-12">
					<h2 className="h2"><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'VIDEOS.TODOLO' }) } } /></h2>
				</div>
			</div>
			<div className="d-flex flex-row my-5 taglist d-none d-lg-flex">
				<div className="px-1 cursorpointer" style={activeTag === null ? {fontWeight: 'bolder'} : {}} onClick={() => searchByTag(null)(null)}><FormattedMessage id='VIDEOS.TODOS' /></div>
				{dataTags?.map((elem, index) =>
					<TagElem
						name={elem.fullName && elem.fullName[lang]}
						id={elem._id}
						onClick={searchByTag(index)}
						active={index === activeTag}
					/>
				)}
			</div>
			{(currentData.length === 0) && <div className="my-4"><FormattedMessage id='VIDEOS.NOVIDEOMATCHES' /></div>}
			{currentData?.map((elem, index) => {
				return (
					<>
						{index % 2 === 0 && <Blockleft
							typecontent="VIDEO"
							elem1={elem[0]}
							elem2={elem[1]}
							elem3={elem[2]}
						/>}
						{index % 2 === 1 && <Blockright
							typecontent="VIDEO"
							elem1={elem[0]}
							elem2={elem[1]}
							elem3={elem[2]}
						/>}
					</>)}
			)}
			<Pagination
				items={data || []}
				setCurrentItems={setCurrentData}
				itemsPerPage={3} // Bloques en este caso (left-right-left)
				callback={() => window.scrollTo(0,0)}
			/>
		</div>
	</>
    	)
}

export default injectIntl(Videos)
