import { getPodcast, getPodcasts, getPodcastsByTag, getPodcastByURL } from '../api/podcast'
import { useMemo } from 'react'

export const usePodcasts = () => {

	return useMemo(() => {

		const handleFetchPodcasts = async ({ featured, tag, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getPodcasts(tag, featured)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data || []

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch podcasts ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchPodcastsByTag = async ({ tags, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getPodcastsByTag(tags)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return []

				return data || []

			} catch (error) {
				/* gestionar el error */
				console.log('could not fetch podcasts ', error)
				return []
			} finally {
				setLoading(false)
			}
		}

		const handleFetchPodcastURL = async ({ url, setLoading = () => {} }) => {
			setLoading(true)

			try {
				const { data } = await getPodcastByURL(url)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
				/* gestionar el error */
				console.log('could not fetch podcast with url: ', url, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchPodcast = async ({ id, setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getPodcast(id)

				/* gestionar quan la resposta es buida (si es dona el cas) */
				if (!data)
					return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
				/* gestionar el error */
				console.log('could not fetch podcast with id: ', id, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchPodcasts,
			handleFetchPodcast,
			handleFetchPodcastsByTag,
			handleFetchPodcastURL
		}

	}, [])
}
